import * as React from "react";
import { useTranslation } from "react-i18next";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import DialogTitle from "@material-ui/core/DialogTitle";

import useStyles from "./index.styles";

interface Props {
  children: React.ReactNode;
  onClose: () => void;
}

const AcknowledgmentTitle: React.FunctionComponent<Props> = ({ children, onClose }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <DialogTitle disableTypography className={classes.titleRoot}>
      <Typography variant="h1">{children}</Typography>
      {onClose ? (
        <IconButton
          onClick={onClose}
          aria-label={t("ariaLabel.close")}
          className={classes.closeButton}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default AcknowledgmentTitle;
