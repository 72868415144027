import { ViewItem, DisplayType } from "../context/treeProvider";

export const getViewItem = (item: any): ViewItem => {
  let displayType: DisplayType = "none";
  if (item.displayType === 1) {
    displayType = "alarmicon";
  } else if (item.displayType === 2) {
    displayType = "linkbox";
  }

  return {
    id: item.id,
    displayType,
    name: item.name,
    parentViewId: item.parentViewId,
    infoBoxPosition: item.infoBoxPosition,
    position: [item.xCoord1, 1 - item.yCoord1],
    position2: [item.xCoord2, 1 - item.yCoord2],
    linkedViewId: item.linkedViewId ? item.linkedViewId : null,
  };
};
