import * as React from "react";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import Icon from "@material-ui/icons/Notifications";
import Typography from "@material-ui/core/Typography";

import useStyles from "./NoAlarms.styles";

interface Props {
  classes?: any;
  dataTestId?: string;
}

const NoAlarms: React.FunctionComponent<Props> = (props) => {
  const classes = useStyles(props);
  const { t } = useTranslation();
  return (
    <div className={classes.root} data-testid={props.dataTestId}>
      <Grid container direction="column" alignItems="center">
        <div className={classes.circle}>
          <Icon className={classes.noAlarmIcon} />
        </div>
        <Typography className={classes.noAlarmText}>{t("alarmCenter.noAlarms")}</Typography>
      </Grid>
    </div>
  );
};

export default NoAlarms;
