import * as React from "react";

const screenfull = require("screenfull");

export interface IContext {
  isFullscreen: boolean;
  toggleFullscreen: () => void;
}
export const Context: React.Context<IContext> = React.createContext({} as IContext);

const FullscreenProvider: React.FunctionComponent = ({ children }) => {
  const [isFullscreen, setIsFullscreen] = React.useState(screenfull.isFullscreen);

  React.useEffect(() => {
    if (screenfull.isEnabled) {
      screenfull.on("change", () => {
        setIsFullscreen(screenfull.isFullscreen);
      });
    }
    return () => {
      if (screenfull.isEnabled) screenfull.off("change");
    };
  });

  return (
    <Context.Provider
      value={{
        isFullscreen,
        toggleFullscreen: () => {
          if (screenfull.isEnabled) {
            screenfull.toggle().then(() => setIsFullscreen(screenfull.isFullscreen));
          }
        },
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default FullscreenProvider;
