import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";
import * as React from "react";

const SystemAlarm: React.FunctionComponent<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path d="M21,2c1.1,0,2,0.9,2,2v12c0,1.1-0.9,2-2,2h-7l2,3v1H8v-1l2-3H3c-1.1,0-2-0.9-2-2V4c0-1.1,0.9-2,2-2H21z M21,15V4H3v11H21z M13,13.5h-2v-2h2V13.5z M13,9.5h-2v-4h2V9.5z" />
  </SvgIcon>
);

export default SystemAlarm;
