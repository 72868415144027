import * as React from "react";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider, { DividerProps } from "@material-ui/core/Divider";

import { Time } from "../Value";
import AlarmIcon from "../Alarm/Icon";
import useTree from "../../hooks/useTree";
import useStyles from "./AlarmList.styles";
import Condition from "../Alarm/Condition";
import AlarmListItemLink from "./AlarmListItemLink";
import useAcknowledgement from "../../hooks/useAcknowledgement";
import { Alarm, Maybe, ViewItem } from "../../generated/graphql";

interface Props {
  alarms: Maybe<ReadonlyArray<Maybe<Alarm>>>;
  DeviderProps?: DividerProps;
  compact?: boolean;
  onClose?: () => void;
  dataTestId?: string;
}

const AlarmList: React.FunctionComponent<Props> = ({
  alarms,
  onClose,
  compact = false,
  DeviderProps = {},
  dataTestId,
}) => {
  const { views } = useTree();
  const classes = useStyles();
  const { t } = useTranslation();
  const { acknowledgeAlarm } = useAcknowledgement();

  if (alarms && views) {
    return (
      <>
        {alarms.map((alarm, index) => {
          if (alarm) {
            let url: string | null = null;
            const viewsItems: ViewItem[] = !!alarm.viewItems
              ? alarm.viewItems.filter((vi) => !!vi)
              : [];

            if (viewsItems && viewsItems[0] && viewsItems[0]!.parentViewId) {
              const view = views.getById(viewsItems[0]!.parentViewId);
              url = view ? `/evaluation${view.path}/${alarm.projectId}-${alarm.slotId}` : null;
            }

            const onClick = compact
              ? () => {
                  if (!url)
                    acknowledgeAlarm(
                      alarm,
                      alarm.projectId,
                      alarm.probeName
                        ? alarm.probeName
                        : alarm.deviceName
                        ? alarm.deviceName
                        : undefined
                    );
                  if (onClose) onClose();
                }
              : () => {
                  if (!url)
                    acknowledgeAlarm(
                      alarm,
                      alarm.projectId,
                      alarm.probeName
                        ? alarm.probeName
                        : alarm.deviceName
                        ? alarm.deviceName
                        : undefined
                    );
                };

            return (
              <React.Fragment key={`alarm-notification-${alarm.ticketId}`}>
                <AlarmListItemLink
                  to={url}
                  noSelect
                  onClick={onClick}
                  classes={{ root: classes.listItem }}
                  dataTestId={dataTestId}
                >
                  <Hidden smDown>
                    <ListItemIcon>
                      <AlarmIcon alarm={alarm} />
                    </ListItemIcon>
                  </Hidden>
                  <ListItemText disableTypography>
                    <Grid container alignItems="center">
                      <Grid
                        item
                        xs={compact ? 9 : 12}
                        md={compact ? 7 : 6}
                        className={compact ? classes.compactText : undefined}
                      >
                        <Grid container alignItems="center">
                          <Hidden mdUp>
                            <Grid item className={classes.mobileIcon}>
                              <AlarmIcon alarm={alarm} />
                            </Grid>
                          </Hidden>
                          <Grid item>
                            {!!alarm.probeName && (
                              <Typography className={classes.slotName}>
                                {alarm.probeName}
                              </Typography>
                            )}
                            {!alarm.probeName && !!alarm.deviceName && (
                              <Typography className={classes.slotName}>
                                {alarm.deviceName}
                              </Typography>
                            )}
                            <Time
                              fromNow
                              time={alarm.timestamp}
                              className={classes.timestamp}
                              TypographyProps={{ variant: "caption" }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      {!compact && (
                        <Grid item xs={12} md={4}>
                          <Condition
                            noDate
                            alarm={alarm}
                            classes={{
                              trigger: classes.trigger,
                            }}
                          />
                        </Grid>
                      )}
                      <Grid
                        item
                        xs={compact ? 3 : 12}
                        md={compact ? 5 : 2}
                        className={compact ? classes.rightIconCompact : classes.rightIcon}
                      >
                        <Button>
                          {url ? t("global.detailsView") : t("evaluation.acknowledgeAlarm")}
                        </Button>
                      </Grid>
                    </Grid>
                  </ListItemText>
                </AlarmListItemLink>
                {index < alarms.length - 1 && <Divider variant="inset" {...DeviderProps} />}
              </React.Fragment>
            );
          }
          return null;
        })}
      </>
    );
  }
  return null;
};

export default AlarmList;
