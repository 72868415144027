import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";

interface State {
  hasError: boolean;
}

interface Props extends WithTranslation {
  children: React.ReactChild;
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, info: React.ErrorInfo) {
    console.log(error, info);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>{this.props.t("global.commonError")}</h1>;
    }

    return this.props.children;
  }
}

export default withTranslation()(ErrorBoundary);
