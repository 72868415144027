import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(
  (theme) => ({
    root: {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(1),
      padding: theme.spacing(3, 6, 3, 6),
      background: theme.palette.grey[100],
    },
    alarm: {
      paddingBottom: theme.spacing(2),
    },
    icon: {
      paddingRight: theme.spacing(),
    },
    timestamp: {
      fontSize: theme.typography.body1.fontSize,
      fontWeight: theme.typography.fontWeightMedium,
    },
    timestampSmall: {
      fontSize: theme.typography.caption.fontSize,
      fontWeight: theme.typography.fontWeightRegular,
    },
    trigger: {
      paddingRight: theme.spacing(2),
    },
    error: {
      color: theme.palette.error.main,
    },
    warning: {
      color: theme.palette.warning.main,
    },
  }),
  { name: "Acknowledgement_AlarmInformation" }
);
