import * as R from "ramda";
import * as React from "react";
import { CollectionEl } from "nested-sets-tree";
import { RouteConfig } from "react-router-config";
import { useLocation, matchPath, Route } from "react-router-dom";

import Tree from "./Tree";
import { View } from "./types";
import { getViewItem } from "../../utils/viewItems";
import { useGetViewQuery } from "../../generated/graphql";

export interface IContext {
  views?: Tree<View>;
  isLoading: boolean;
  isEvaluation: boolean;
  error?: Error;
  activePath: string;
}

interface Props {
  routes: RouteConfig[];
}

export const TreeContext: React.Context<IContext> = React.createContext({} as IContext);

const TreeProvider: React.FunctionComponent<Props> = ({ children }) => {
  let activePath = "/";
  let isEvaluation = false;
  const location = useLocation();

  const match = matchPath<string>(location.pathname, {
    path: "/(view|evaluation)/*",
  });

  if (match && match.params && match.params[1]) {
    activePath = `/${match.params[1]}`;
    if (match.params[0] === "evaluation") {
      const activePathParts = R.split("/", activePath);
      isEvaluation = true;
      activePath = R.join("/")(R.slice(0, activePathParts.length - 1)(activePathParts));
    }
  }

  const { data, error, loading } = useGetViewQuery();

  if (loading) {
    return (
      <TreeContext.Provider
        value={{
          activePath,
          isEvaluation,
          isLoading: loading,
          error,
        }}
      >
        <Route location={location} render={() => children} />
      </TreeContext.Provider>
    );
  }

  if (data && data.views) {
    const treeCollection = data.views
      ? ((data.views
          .filter((view) => {
            if (!view) return false;
            if (view.parentId === -1 || !view.parentId) return true;
            return !!view.viewItems && !!view.viewItems.length;
          })
          .map((view) => {
            const viewItems = view.viewItems!.map((vi) => getViewItem(vi));
            return {
              ...view,
              viewItems,
            };
          }) as unknown) as CollectionEl[])
      : [];

    const views = new Tree<View>(treeCollection);

    return (
      <TreeContext.Provider
        value={{
          views,
          activePath,
          isEvaluation,
          isLoading: loading,
          error,
        }}
      >
        <Route location={location} render={() => children} />
      </TreeContext.Provider>
    );
  }

  return null;
};

export default TreeProvider;
