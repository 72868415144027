import * as React from "react";
import Drawer from "@material-ui/core/Drawer";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Theme } from "@material-ui/core/styles/createMuiTheme";

import Logo from "../Logo";
import MenuList from "./MenuList";
import useStyles from "./index.styles";
import useFullscreen from "../../hooks/useFullscreen";
import { Tree, TreeNode } from "../../context/treeProvider";

interface Props {
  tree: Tree<TreeNode> | null;
  isOpen?: boolean;
  onClose?: () => void;
  dataTestIds?: {
    root: string;
    item: string;
    badge: string;
  };
}

const MainMenu: React.FunctionComponent<Props> = ({
  tree,
  onClose,
  isOpen = false,
  dataTestIds,
}) => {
  const classes = useStyles();
  const { isFullscreen } = useFullscreen();
  const theme = useTheme<Theme>();
  const matches = useMediaQuery(theme.breakpoints.down("sm"), { noSsr: true });

  const isTemporaryDrawer = matches || isFullscreen;

  const L = <Logo inverse showTesto className={classes.logo} imageClassName={classes.logoImg} />;

  return (
    <Drawer
      anchor="left"
      open={isTemporaryDrawer ? isOpen : undefined}
      variant={isTemporaryDrawer ? "temporary" : "permanent"}
      className={classes.root}
      PaperProps={{ elevation: 8 }}
      onClose={onClose ? onClose : undefined}
      classes={{ paper: classes.paper }}
    >
      {!isTemporaryDrawer && <div className={classes.toolbar} />}
      {!!tree && <MenuList tree={tree} dataTestIds={dataTestIds} hasLogo={!!L} />}
      {L}
    </Drawer>
  );
};

export default MainMenu;
