import { useContext } from "react";

import { AlarmContext, IContext } from "../context/alarmProvider";

const useAlarmsTotal = () => {
  const alarmContext: IContext = useContext(AlarmContext);

  return { loading: alarmContext.loading, count: alarmContext.totalAlarms };
};

export default useAlarmsTotal;
