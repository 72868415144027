import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(
  (theme) => ({
    root: {
      width: 200,
      padding: theme.spacing(4),
      textAlign: "center",
    },

    circle: {
      display: "flex",
      width: theme.spacing(21),
      height: theme.spacing(21),
      borderRadius: "100%",
      background: theme.palette.grey[200],
      alignItems: "center",
      justifyContent: "center",
    },

    noAlarmIcon: {
      color: theme.palette.grey[400],
      opacity: 0.4,
      fontSize: 80,
      transform: "rotate(-45deg)",
    },

    noAlarmText: {
      paddingTop: theme.spacing(1),
      color: theme.palette.grey[400],
      fontWeight: theme.typography.fontWeightBold,
    },
  }),
  { name: "AlarmCenter_NoAlarms" }
);
