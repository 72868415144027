import { useContext } from "react";

import { AuthContext, IContext } from "../context/authProvider";

interface UseAuth {
  (): IContext;
}

const useAuth: UseAuth = (): IContext => {
  const authContext: IContext = useContext(AuthContext);
  return authContext;
};

export default useAuth;
