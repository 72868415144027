import clsx from "clsx";
import moment from "moment";
import * as React from "react";
import Typography, { TypographyProps } from "@material-ui/core/Typography";

import useStyles from "./index.styles";

interface Props {
  time?: number | null;
  timeFormat?: string;
  utc?: boolean;
  fromNow?: boolean;
  classes?: {};
  className?: string;
  disableTypography?: boolean;
  TypographyProps?: TypographyProps;
}

const Time: React.FunctionComponent<Props> = (props) => {
  const classes = useStyles(props);
  const {
    time,
    timeFormat = "LLL",
    utc = true,
    fromNow = false,
    disableTypography,
    TypographyProps,
    className,
  } = props;

  if (time && time > 0) {
    const m = utc ? moment.utc(time) : moment(time);
    const str = fromNow ? m.local().fromNow() : m.local().format(timeFormat);
    if (disableTypography) {
      return <>{str}</>;
    }
    return (
      <Typography className={clsx(classes.text, className)} variant="body2" {...TypographyProps}>
        {str}
      </Typography>
    );
  }
  return null;
};

export default Time;
