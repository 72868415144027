import i18next from "i18next";
import Backend from "i18next-chained-backend";
import I18nextFetch from "i18next-fetch-backend";
import { initReactI18next } from "react-i18next";
import LocalStorageBackend from "i18next-localstorage-backend";
import LanguageDetector from "i18next-browser-languagedetector";

export const i18n = i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(Backend);

export const i18nInit = i18n.init({
  debug: process.env.NODE_ENV !== "production",
  defaultNS: "common",
  ns: ["common"],
  fallbackLng: process.env.NODE_ENV !== "production" ? "dev" : "en",
  detection: {
    order: ["localStorage", "navigator"],
  },
  backend: {
    backends: [LocalStorageBackend, I18nextFetch],
    backendOptions: [
      {
        versions: {
          dev: process.env.REACT_APP_BUILD,
          de: process.env.REACT_APP_BUILD,
          en: process.env.REACT_APP_BUILD,
          "cs-CZ": process.env.REACT_APP_BUILD,
          "da-DK": process.env.REACT_APP_BUILD,
          "de-DE": process.env.REACT_APP_BUILD,
          "en-GB": process.env.REACT_APP_BUILD,
          "en-US": process.env.REACT_APP_BUILD,
          "es-ES": process.env.REACT_APP_BUILD,
          "fr-FR": process.env.REACT_APP_BUILD,
          "hu-HU": process.env.REACT_APP_BUILD,
          "it-IT": process.env.REACT_APP_BUILD,
          "ja-JP": process.env.REACT_APP_BUILD,
          "nl-NL": process.env.REACT_APP_BUILD,
          "pl-PL": process.env.REACT_APP_BUILD,
          "pt-PT": process.env.REACT_APP_BUILD,
          "ru-RU": process.env.REACT_APP_BUILD,
          "sv-SE": process.env.REACT_APP_BUILD,
          "tr-TR": process.env.REACT_APP_BUILD,
          "zh-CN": process.env.REACT_APP_BUILD,
          "zh-HK": process.env.REACT_APP_BUILD,
        },
        expirationTime: 7 * 24 * 60 * 60 * 1000,
      },
      {
        loadPath: (lngs: string, namespaces: string) => {
          const lng = lngs.split("-");
          let language = lngs;
          if (lng.length === 1 && lng[0] === "en") {
            language = "en-GB";
          }
          if (lng.length === 1 && lng[0] === "de") {
            language = "de-DE";
          }
          return `${process.env.PUBLIC_URL}/nls/${namespaces}-${language}.json`;
        },
      },
    ],
  },
});
