import {
  useAlarmAddedSubscription,
  useAlarmRemovedSubscription,
  GetAlarmsQuery,
  Exact,
} from "../../generated/graphql";
import { useApolloClient, ApolloQueryResult } from "@apollo/client";

const clearCacheKeys = (cache: any) => {
  Object.keys(cache.data.data.ROOT_QUERY).forEach((key) => {
    if (key.match(/^alarms/)) {
      delete cache.data.data.ROOT_QUERY[key];
    }
  });
};

export default (
  refetch: (
    variables?:
      | Partial<
          Exact<{
            offset?: number | null | undefined;
            limit?: number | null | undefined;
          }>
        >
      | undefined
  ) => Promise<ApolloQueryResult<GetAlarmsQuery>>,
  variables: { limit: number; offset: number }
) => {
  const client = useApolloClient();
  useAlarmAddedSubscription({
    onSubscriptionData: () => {
      clearCacheKeys(client.cache);
      refetch(variables);
    },
  });
  useAlarmRemovedSubscription({
    onSubscriptionData: () => {
      clearCacheKeys(client.cache);
      refetch(variables);
    },
  });
};
