import * as React from "react";
import { ApolloError } from "@apollo/client";

import AlarmPollUpdater from "./AlarmPollUpdater";
import { sortAlarmsBySeverity } from "../../utils/alarms";
import AlarmAddedSubscription from "./AlarmAddedSubscription";
import AlarmRemovedSubscription from "./AlarmRemovedSubscription";
import { useGetAlarmsQuery, SlotId, Alarm } from "../../generated/graphql";

export interface IContext {
  totalAlarms: number;
  loading: boolean;
  error?: ApolloError;
  getAlarmsInSlots: (slots: SlotId[]) => Alarm[];
}

export const AlarmContext: React.Context<IContext> = React.createContext({
  totalAlarms: 0,
  loading: false,
} as IContext);

const AlarmProvider: React.FunctionComponent = ({ children }) => {
  const { data, loading, error } = useGetAlarmsQuery({ pollInterval: 1000 * 60 * 5 });

  const totalAlarms = data && data.alarms && !isNaN(data.alarms.total) ? data.alarms.total : 0;
  const alarms = data && data.alarms && data.alarms.result ? data.alarms.result : [];

  return (
    <>
      <AlarmAddedSubscription />
      <AlarmRemovedSubscription />
      <AlarmPollUpdater totalAlarms={totalAlarms} />
      <AlarmContext.Provider
        value={{
          error,
          loading,
          totalAlarms,
          getAlarmsInSlots: (slots: SlotId[]) => {
            const res = sortAlarmsBySeverity(
              alarms.filter((alarm) =>
                slots.find(
                  (slot) => slot.projectId === alarm.projectId && slot.slotId === alarm.slotId
                )
              )
            );
            return res;
          },
        }}
      >
        {children}
      </AlarmContext.Provider>
    </>
  );
};

export default AlarmProvider;
