import * as R from "ramda";
import NestedSets, { CollectionEl } from "nested-sets-tree";

import { TreeNode } from "./types";

interface IPredicate {
  id?: string | number;
  path?: string;
  name?: string;
}

class Tree<P extends TreeNode> extends NestedSets {
  constructor(data?: CollectionEl[]) {
    super({
      id: "id",
      lvl: "depth",
      parentId: "parentId",
      lft: "lft",
      rgt: "rgt",
      hide: "hide",
    });

    if (data) {
      this.loadTree(data, { createIndexes: true });
    }
  }

  public find(predicate: IPredicate): P | null {
    const ele = R.find<CollectionEl>(R.whereEq(predicate))(this.all);
    if (ele) {
      return (ele as unknown) as P;
    }
    return null;
  }

  public getRoot(): P | null {
    if (this.all.length) {
      return (this.getRootEl().results[0] as unknown) as P;
    }
    return null;
  }

  public getRootNodes(): P[] | null {
    if (this.all.length) {
      return (this.getRootCats().results as unknown) as P[];
    }
    return null;
  }

  public getAll(): P[] {
    if (this.all.length) {
      return (this.all as unknown) as P[];
    }
    return [];
  }

  public getByPath(path: string): P | null {
    return this.find({ path });
  }

  public getById(id: string | number): P | null {
    const view = this.getElById(id).results[0];
    if (view) {
      return (view as unknown) as P;
    }
    return null;
  }

  public getParents(id: string | number, stripRoot = false): P[] | null {
    const parents = (this.getAllParents(id, false).results as unknown) as P[];
    if (parents && parents.length) {
      if (stripRoot) {
        return R.remove(0, 1, parents);
      }
      return parents;
    }
    return null;
  }

  public getAllChildren(id: string | number): P[] | null {
    const childs = this.getAllChilds(id, false).results;
    if (childs && childs.length) {
      return (childs as unknown) as P[];
    }
    return null;
  }

  public getChildren(id: string | number): P[] | null {
    const childs = this.getChilds(id, false).results;
    if (childs && childs.length) {
      return (childs as unknown) as P[];
    }
    return null;
  }

  public getSiblings(id: string | number): P[] | null {
    const parent = this.getParent(id).results[0];
    if (parent) {
      return this.getChildren(parent.id);
    }
    return null;
  }

  public getNode(id: string | number): P | null {
    return this.find({ id });
  }

  public isLeaf(node: P): boolean {
    return node.lft + 1 === node.rgt;
  }

  public getLeafs(id: string | number): P[] | null {
    const leafs = this.filterLeafs(this.getAllChildren(id) || []);
    if (leafs && leafs.length) {
      return leafs;
    }
    return null;
  }

  private filterLeafs = R.filter<P>(this.isLeaf);
}

export default Tree;
