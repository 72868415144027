export enum AlarmTagEnum {
  LOWER_LIMIT = 1,
  UPPER_LIMIT = 2,
  LOWER_LIMIT_WARNING = 4,
  UPPER_LIMIT_WARNING = 5,
  EVENT = 3,
  LOWER_LIMIT_GRAD = 6,
  UPPER_LIMIT_GRAD = 7,
}

export enum AlarmEventsEnum {
  LOW_BATTERY = 1,
  CONNECTION_STATUS = 2,
  PWRSPLY = 3,
  CALIBRATION_DATE = 4,
  SENSOR_ERROR = 5,
  GSM_NOT_AVAILABLE = 7,
  GSM_QUEUE_FULL = 8,
  LOW_MEMORY = 9,
  BATTERY_DEFECT = 10,
  COMMUNICATION_APP = 11,
  MEMORY_FULL = 12,
  GSM_MODUL_FAILED = 13,
  ALARM_SCHEDULE_STATE = 14,
}
