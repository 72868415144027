import * as React from "react";

import { Alarm } from "../../generated/graphql";
import Acknowledgment from "../../components/Acknowledgment";

export interface IContext {
  acknowledgeAlarm: (alarm: Alarm, projectId?: number, deviceName?: string) => void;
  closeAcknowledgeAlarm: () => void;
}
export const Context: React.Context<IContext> = React.createContext({} as IContext);

const AcknowledgementProvider: React.FunctionComponent = ({ children }) => {
  const [ack, setAck] = React.useState<{
    projectId: number | null;
    alarm: Alarm;
    deviceName?: string | null;
  } | null>(null);

  return (
    <Context.Provider
      value={{
        acknowledgeAlarm: (alarm: Alarm, projectId?: number, deviceName?: string) => {
          setAck({ alarm, projectId: projectId || null, deviceName });
        },
        closeAcknowledgeAlarm: () => {
          setAck(null);
        },
      }}
    >
      <>
        {children}
        <Acknowledgment
          onClose={() => setAck(null)}
          open={!!ack}
          alarm={ack ? ack.alarm : null}
          projectId={ack ? ack.projectId : null}
          deviceName={ack ? ack.deviceName : undefined}
        />
      </>
    </Context.Provider>
  );
};

export default AcknowledgementProvider;
