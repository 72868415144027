import * as R from "ramda";
import { ApolloClient } from "@apollo/client";

import {
  GetAlarmsDocument,
  GetAlarmsQuery,
  Alarm,
  GetSlotQuery,
  GetSlotDocument,
} from "../../generated/graphql";

const updateAlarmsTotal = async (client: ApolloClient<object>, count: number) => {
  let changed = false;
  const cachedAlarms = client.readQuery<GetAlarmsQuery>({
    query: GetAlarmsDocument,
  });
  if (cachedAlarms && cachedAlarms.alarms && cachedAlarms.alarms.total) {
    if (cachedAlarms.alarms.total !== count) changed = true;
    await client.query<GetAlarmsQuery>({ query: GetAlarmsDocument, fetchPolicy: "network-only" });
  }
  return changed;
};

const updateSlots = async (
  client: ApolloClient<object>,
  action: "added" | "removed",
  alarm: Alarm
) => {
  let changed = false;
  try {
    const cachedSlot = client.readQuery<GetSlotQuery>({
      query: GetSlotDocument,
      variables: { projectId: alarm.projectId, slotId: alarm.slotId },
    });

    if (cachedSlot && cachedSlot.slot) {
      const slot = R.clone(cachedSlot.slot);
      if (
        action === "removed" &&
        slot.alarms &&
        slot.alarms.findIndex((a) => a.ticketId === alarm.ticketId) > -1
      ) {
        changed = true;
        slot.alarms = R.filter((a: any) => a.ticketId !== alarm.ticketId)(slot.alarms);
      }

      if (action === "added") {
        slot.alarms = slot.alarms && slot.alarms.length ? slot.alarms : [];
        slot.alarms.push(alarm);
        changed = true;
      }

      if (changed) {
        client.writeQuery<GetSlotQuery>({
          query: GetSlotDocument,
          variables: { projectId: slot.projectId, slotId: slot.slotId },
          data: { slot },
        });
      }
    }
  } catch (err) {
    console.log(err);
    console.log(`slot (${alarm.projectId}-${alarm.slotId}) not loaded yet`);
  }

  return changed;
};

export const updateCache = async (
  client: ApolloClient<object>,
  total: number,
  changed: Alarm,
  action: "added" | "removed"
) => {
  let changedTotal = false,
    changedSlot = false;
  if (!isNaN(total)) {
    changedTotal = await updateAlarmsTotal(client, total);
  }
  changedSlot = await updateSlots(client, action, changed);
  return changedTotal && changedSlot;
};

export default updateCache;
