import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";
import * as React from "react";

const GsmQueueFull: React.FunctionComponent<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path d="M12,5c3.9,0,7,3.1,7,7h-2c0-2.8-2.2-5-5-5s-5,2.2-5,5H5C5,8.1,8.1,5,12,5z M13,14.3v8.3h-2v-8.3C10.1,13.9,9.5,13,9.5,12c0-1.4,1.1-2.5,2.5-2.5s2.5,1.1,2.5,2.5C14.5,13,13.9,13.9,13,14.3z M12,1c6.1,0,11,4.9,11,11h-2c0-5-4-9-9-9s-9,4-9,9H1C1,5.9,5.9,1,12,1z M17,19v-5h2v5H17z M17,23v-2h2v2H17z" />
  </SvgIcon>
);

export default GsmQueueFull;
