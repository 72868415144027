import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(
  (theme) => ({
    icon: {
      color: theme.palette.grey[800],
    },
    text: {
      color: theme.palette.grey[800],
      fontWeight: theme.typography.fontWeightMedium,
    },
    noIcon: {
      paddingLeft: theme.spacing() / 2,
    },
    unknowIcon: {
      display: "inline-block",
      width: theme.spacing(4),
    },
  }),
  { name: "Alarm_Trigger" }
);
