import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";
import * as React from "react";

const ConnectionStatus: React.FunctionComponent<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path
      style={{ fillRule: "evenodd", clipRule: "evenodd" }}
      d="M14.8,16.9h-3H8.7l-2.4-2.6H2.2v-3.8h4.1l1-1.1L2.2,4.3l1.4-1.4l17.5,17.5l-1.4,1.4L14.8,16.9z M17.9,14.5l-6.6-6.6h1.3h3.1l2.4,2.6h4.1v3.8h-4.1L17.9,14.5z"
    />
  </SvgIcon>
);

export default ConnectionStatus;
