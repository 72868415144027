import * as React from "react";
import Grid from "@material-ui/core/Grid";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Theme } from "@material-ui/core/styles/createMuiTheme";

import Value, { Time } from "../Value";
import AlarmTrigger from "./Trigger";
import useStyles from "./Condition.styles";
import { TestoUnitsEnum } from "../Value/units";
import { Alarm } from "../../generated/graphql";
import { getClassFromSeverity } from "../../utils/alarms";

interface Props {
  alarm?: Alarm;
  unit?: TestoUnitsEnum;
  lastValue?: number | null;
  noDate?: boolean;
  colorClass?: string;
  fromNow?: boolean;
  timeFormat?: string;
  classes?: any;
  dataTestId?: string;
}

const AlarmCondition: React.FunctionComponent<Props> = (props) => {
  const classes = useStyles(props);
  const {
    alarm,
    lastValue,
    unit,
    colorClass,
    noDate = false,
    fromNow = true,
    timeFormat = "LLL",
    dataTestId,
  } = props;
  const theme = useTheme<Theme>();
  const matches = useMediaQuery(theme.breakpoints.down("sm"), { noSsr: true });
  const cc = colorClass
    ? colorClass
    : alarm
    ? getClassFromSeverity(alarm.severity, classes.error, classes.warning)
    : undefined;

  return (
    <Grid
      container
      alignItems="center"
      spacing={0}
      justify={matches ? "flex-start" : "flex-end"}
      data-testid={dataTestId}
    >
      {!noDate && (
        <Grid item className={classes.time} xs={12} md={4}>
          {!!alarm && alarm.timestamp && alarm.timestamp > 0 && (
            <Time fromNow={fromNow} timeFormat={timeFormat} time={alarm.timestamp} className={cc} />
          )}
        </Grid>
      )}
      <Grid item className={classes.trigger} xs={6} md={!noDate ? 4 : 6}>
        <AlarmTrigger colorClass={cc} alarm={alarm} />
      </Grid>
      {unit && lastValue && (
        <Grid item className={classes.lastValue} xs={6} md={!noDate ? 4 : 6}>
          <Value colorClass={cc} value={lastValue} unit={unit} />
        </Grid>
      )}
    </Grid>
  );
};

export default AlarmCondition;
