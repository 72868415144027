import clsx from "clsx";
import * as React from "react";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";

import useStyles from "./index.styles";
import { getTypeIcon } from "./icons";
import { formatValue } from "../../Value/units";
import { getEventString } from "../../../utils/alarms";
import { AlarmTagEnum } from "../../../context/alarmProvider";
import { Alarm, AlarmEntry, AlarmCondition } from "../../../generated/graphql";

interface Props {
  alarm?: Alarm;
  noIcon?: boolean;
  colorClass?: string;
  classes?: any;
  dataTestId?: string;
}

const renderConditionVal = (entry: AlarmEntry | null | undefined, valCondition: AlarmCondition) => {
  let res = "";
  if (valCondition.limit && valCondition.limit.value !== null) {
    res += formatValue(valCondition.limit.value, valCondition.limit.unit);
  }

  if (entry) {
    switch (entry.type) {
      case AlarmTagEnum.LOWER_LIMIT:
      case AlarmTagEnum.LOWER_LIMIT_GRAD:
      case AlarmTagEnum.LOWER_LIMIT_WARNING:
        res += " < ";
        break;
      case AlarmTagEnum.UPPER_LIMIT:
      case AlarmTagEnum.UPPER_LIMIT_GRAD:
      case AlarmTagEnum.UPPER_LIMIT_WARNING:
        res += " > ";
        break;
    }
  }

  return res;
};

const AlarmTrigger: React.FunctionComponent<Props> = (props) => {
  const classes = useStyles(props);
  const { alarm, noIcon = false, colorClass, dataTestId } = props;
  const { t } = useTranslation();

  if (alarm && alarm.currentCondition) {
    const entry = alarm.currentCondition;
    const valCondition =
      alarm.targetCondition && alarm.targetCondition.condition
        ? alarm.targetCondition.condition
        : null;
    const Icon =
      entry.condition && entry.condition.status && entry.condition.status.event
        ? getTypeIcon(entry.type, entry.condition.status.event)
        : getTypeIcon(entry.type);

    return (
      <Grid container alignItems="center" data-testid={dataTestId}>
        {!noIcon && (
          <Grid item>
            {Icon ? (
              <Icon className={clsx(classes.icon, colorClass)} />
            ) : (
              <span className={classes.unknowIcon} />
            )}
          </Grid>
        )}
        {entry.type !== AlarmTagEnum.EVENT && entry.condition && entry.condition.limit && (
          <Grid item className={noIcon ? classes.noIcon : undefined}>
            <Typography variant="body2" className={clsx(classes.text, colorClass)}>
              {!!valCondition && renderConditionVal(entry, valCondition)}
              {entry.condition.limit.value !== null &&
                formatValue(entry.condition.limit.value, entry.condition.limit.unit)}
            </Typography>
          </Grid>
        )}
        {entry.type === AlarmTagEnum.EVENT && !!entry.condition && entry.condition.status && (
          <Grid item className={classes.noIcon}>
            <Typography variant="body2" className={clsx(classes.text, colorClass)}>
              {getEventString(entry.condition.status.event, t)}
            </Typography>
          </Grid>
        )}
      </Grid>
    );
  }
  return null;
};

export default AlarmTrigger;
