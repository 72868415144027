import clsx from "clsx";
import * as React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import useStyles from "./index.styles";
import TIcon from "../Icons/Temperature";
import HIcon from "../Icons/RelativeHumidity";
import { TestoUnitsEnum, formatValue } from "./units";

interface Props {
  value: number;
  unit?: TestoUnitsEnum;
  colorClass?: string;
}

const Value: React.FunctionComponent<Props> = ({ unit, value, colorClass }) => {
  const classes = useStyles();
  let Icon = null;
  switch (unit) {
    case TestoUnitsEnum.GRAD_C:
    case TestoUnitsEnum.GRAD_C_TM:
    case TestoUnitsEnum.GRAD_C_TW:
    case TestoUnitsEnum.GRAD_F:
    case TestoUnitsEnum.GRAD_F_TM:
    case TestoUnitsEnum.GRAD_F_TW:
    case TestoUnitsEnum.TAUPUNKT:
      Icon = TIcon;
      break;
    case TestoUnitsEnum.REL_FEUCHTE:
    case TestoUnitsEnum.ABS_HUMIDITY:
    case TestoUnitsEnum.DRY_HUMIDITY:
    case TestoUnitsEnum.REL_HUMIDITY:
    case TestoUnitsEnum.REL_HUMIDITY_WMO:
      Icon = HIcon;
      break;
    default:
      Icon = null;
      break;
  }

  return (
    <Grid container alignItems="center">
      <Grid item>
        {Icon ? (
          <Icon className={clsx(classes.icon, colorClass)} />
        ) : (
          <span className={classes.unknowIcon} />
        )}
      </Grid>
      <Grid item>
        <Typography variant="body2" className={clsx(classes.text, colorClass)}>
          {formatValue(value, unit)}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Value;
