import moment from "moment";
import numbro from "numbro";
import * as React from "react";
import Radio from "@material-ui/core/Radio";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";
import CloseIcon from "@material-ui/icons/Close";
import { useTheme } from "@material-ui/core/styles";
import RadioGroup from "@material-ui/core/RadioGroup";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import useStyles from "./index.styles";

interface Props {
  open: boolean;
  onClose?: () => void;
  dataTestId?: string;
}

const LanguageSwitch: React.FunctionComponent<Props> = ({ open, onClose, dataTestId }) => {
  const classes = useStyles();
  const theme = useTheme<Theme>();
  const { t, i18n } = useTranslation();
  const matches = useMediaQuery(theme.breakpoints.down("sm"), { noSsr: true });
  const [language, setLanguage] = React.useState<string>(i18n.language);

  const handleClose = () => {
    setLanguage(i18n.language);
    if (onClose) onClose();
  };

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      onClose={onClose}
      fullScreen={matches}
      classes={{
        paper: classes.root,
      }}
      data-testid={dataTestId}
    >
      <DialogTitle disableTypography className={classes.titleRoot}>
        <Typography variant="h1" gutterBottom>
          {t("languageSwitch.headline")}
        </Typography>
        <Typography>{t("languageSwitch.subline")}</Typography>
        {onClose ? (
          <IconButton
            onClick={handleClose}
            className={classes.closeButton}
            aria-label={t("ariaLabel.close")}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent className={classes.contentRoot}>
        <FormControl className={classes.formControl}>
          <RadioGroup
            aria-label={t("languageSwitch.aria-label")}
            name="language"
            value={language}
            onChange={(event: React.ChangeEvent<any>) => setLanguage(event.target.value)}
          >
            <FormControlLabel
              classes={{ root: classes.formControlLabel }}
              value="cs-CZ"
              control={<Radio />}
              label={t("languageSwitch.czech")}
            />
            <FormControlLabel
              classes={{ root: classes.formControlLabel }}
              value="da-DK"
              control={<Radio />}
              label={t("languageSwitch.danish")}
            />
            <FormControlLabel
              classes={{ root: classes.formControlLabel }}
              value="de-DE"
              control={<Radio />}
              label={t("languageSwitch.german")}
            />
            <FormControlLabel
              classes={{ root: classes.formControlLabel }}
              value="en-GB"
              control={<Radio />}
              label={t("languageSwitch.english_uk")}
            />
            <FormControlLabel
              classes={{ root: classes.formControlLabel }}
              value="en-US"
              control={<Radio />}
              label={t("languageSwitch.english_us")}
            />
            <FormControlLabel
              classes={{ root: classes.formControlLabel }}
              value="es-ES"
              control={<Radio />}
              label={t("languageSwitch.spanish")}
            />
            <FormControlLabel
              classes={{ root: classes.formControlLabel }}
              value="fr-FR"
              control={<Radio />}
              label={t("languageSwitch.french")}
            />
            <FormControlLabel
              classes={{ root: classes.formControlLabel }}
              value="hu-HU"
              control={<Radio />}
              label={t("languageSwitch.hungarian")}
            />
            <FormControlLabel
              classes={{ root: classes.formControlLabel }}
              value="it-IT"
              control={<Radio />}
              label={t("languageSwitch.italian")}
            />
            <FormControlLabel
              classes={{ root: classes.formControlLabel }}
              value="ja-JP"
              control={<Radio />}
              label={t("languageSwitch.japanese")}
            />
            <FormControlLabel
              classes={{ root: classes.formControlLabel }}
              value="nl-NL"
              control={<Radio />}
              label={t("languageSwitch.dutch")}
            />
            <FormControlLabel
              classes={{ root: classes.formControlLabel }}
              value="pl-PL"
              control={<Radio />}
              label={t("languageSwitch.polish")}
            />
            <FormControlLabel
              classes={{ root: classes.formControlLabel }}
              value="pt-PT"
              control={<Radio />}
              label={t("languageSwitch.portuguese")}
            />
            <FormControlLabel
              classes={{ root: classes.formControlLabel }}
              value="ru-RU"
              control={<Radio />}
              label={t("languageSwitch.russian")}
            />
            <FormControlLabel
              classes={{ root: classes.formControlLabel }}
              value="sv-SE"
              control={<Radio />}
              label={t("languageSwitch.swedish")}
            />
            <FormControlLabel
              classes={{ root: classes.formControlLabel }}
              value="tr-TR"
              control={<Radio />}
              label={t("languageSwitch.turkish")}
            />
            <FormControlLabel
              classes={{ root: classes.formControlLabel }}
              value="zh-CN"
              control={<Radio />}
              label={t("languageSwitch.chinese_cn")}
            />
            <FormControlLabel
              classes={{ root: classes.formControlLabel }}
              value="zh-HK"
              control={<Radio />}
              label={t("languageSwitch.chinese_hk")}
            />
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t("global.cancel")}</Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            moment.locale(language);
            i18n.changeLanguage(language);
            numbro.setLanguage(language);
            if (onClose) onClose();
          }}
        >
          {t("global.save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LanguageSwitch;
