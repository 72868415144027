import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(
  theme => ({
    root: {
      padding: theme.spacing(5)
    },
    titleRoot: {
      margin: theme.spacing(2),
      padding: 0,
      textAlign: "center"
    },
    contentRoot: {
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2)
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500]
    },
    formControl: {
      width: "100%"
    },
    formControlLabel: {
      marginRight: 0,
      borderBottom: `1px solid ${theme.palette.divider}`
    }
  }),
  { name: "LanguageSwitch" }
);
