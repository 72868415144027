import * as React from "react";

import { useGetUserQuery } from "../../generated/graphql";

export interface User {
  id: string;
  nameID: string;
  displayName: string;
  roles: string[];
  can: (action: "admin" | "access" | "acknowledge") => boolean;
}

export const UserContext: React.Context<User> = React.createContext({} as User);

const UserProvider: React.FunctionComponent = ({ children }) => {
  const { data, loading, error } = useGetUserQuery();

  if (data && !loading && !error) {
    const user: User = {
      id: data.user ? data.user.id : "",
      nameID: data.user ? data.user.nameID : "",
      displayName: data.user ? data.user.displayName : "",
      roles: data.user && data.user.roles ? data.user.roles : [],
      can: (action: "admin" | "access" | "acknowledge") => {
        switch (action) {
          case "admin":
            return !!user.roles && user.roles.includes("admin");
          case "access":
            return !!user.roles && user.roles.includes("access");
          case "acknowledge":
            return !!user.roles && user.roles.includes("acknowledge");
          default:
            return false;
        }
      },
    };
    return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
  }
  return null;
};

export default UserProvider;
