import { createMuiTheme, Theme } from "@material-ui/core/styles";
import { ThemeOptions } from "@material-ui/core/styles/createMuiTheme";

const muiDefaults = createMuiTheme();

declare module "@material-ui/core/styles/createPalette" {
  interface Palette {
    success: PaletteColor;
    warning: PaletteColor;
  }
  interface PaletteOptions {
    success?: PaletteColorOptions;
    warning?: PaletteColorOptions;
  }
}

const htmlFontSize = 10;

declare module "@material-ui/core/styles/createMuiTheme" {
  interface Theme {
    appDrawer: {
      width: React.CSSProperties["width"];
    };
    /**
     * Chart colors for Saveris
     *
     * Set global highchart colors option
     * @example
     * Highcharts.setOptions({
     *  colors: theme.charts.seriesColors.main
     * });
     *
     * or set the color for one chart
     * @example
     * Highcharts.chart({
     *  colors: theme.charts.seriesColors.main
     * });
     *
     * Need more colors?
     * @example
     * Highcharts.chart({
     *  colors: [...theme.charts.seriesColors.main, ...theme.charts.seriesColors.dark, ...theme.charts.seriesColors.light]
     * });
     */
    charts: {
      seriesColors: {
        dark: string[];
        light: string[];
        main: string[];
      };
    };
  }
  // allow configuration using `createMuiTheme`
  interface ThemeOptions {
    appDrawer?: {
      width: React.CSSProperties["width"];
    };
    charts?: {
      seriesColors: {
        dark: string[];
        light: string[];
        main: string[];
      };
    };
  }
}

export function createSaveris1Theme(options?: ThemeOptions): Theme {
  const palette = {
    background: {
      default: "#FAFAFA",
      paper: "#FFFFFF",
    },
    grey: {
      50: "#FAFAFA",
      100: "#F5F5F5",
      200: "#EEEEEE",
      300: "#E0E0E0",
      400: "#BDBDBD",
      500: "#9E9E9E",
      600: "#757575",
      700: "#616161",
      800: "#424242",
      900: "#212121",
    },
    primary: {
      contrastText: "#FFFFFF",
      dark: "#212121",
      main: "#424242",
      light: "#616161",
    },
    secondary: {
      contrastText: "#FFFFFF",
      dark: "#F57D00",
      main: "#FF9900",
      light: "#FFB84D",
    },
    success: {
      contrastText: "#FFFFFF",
      dark: "#008220",
      main: "#13A537",
      light: "#4DC05F",
    },
    warning: {
      contrastText: "#FFFFFF",
      dark: "#FC7600",
      main: "#FEA200",
      light: "#FEBC00",
    },
    error: {
      contrastText: "#FFFFFF",
      dark: "#C90000",
      main: "#E30615",
      light: "#FF2D17",
    },
  };

  const themeOptions: ThemeOptions = {
    appDrawer: {
      width: 240,
    },
    palette,
    typography: {
      htmlFontSize,
      h1: {
        color: palette.grey[700],
        fontWeight: 500,
        fontSize: "2.6rem",
        letterSpacing: 0,
      },
      h2: {
        fontWeight: 500,
        fontSize: "1.8rem",
        letterSpacing: "0.25px",
      },
      h3: {
        fontWeight: 400,
        fontSize: "1.6rem",
        letterSpacing: "0.5px",
      },
      h4: {
        fontWeight: 500,
        fontSize: "1.4rem",
        letterSpacing: "0.5px",
      },
      h5: {
        fontWeight: 500,
        fontSize: "1.4rem",
        letterSpacing: "0.5px",
      },
      h6: {
        fontWeight: 500,
        fontSize: "1.4rem",
        letterSpacing: "0.5px",
      },
      body1: {
        fontWeight: 400,
        fontSize: "1.6rem",
        letterSpacing: "0.5px",
      },
      body2: {
        fontWeight: 400,
        fontSize: "1.4rem",
        letterSpacing: "0.25px",
      },
      subtitle1: {
        fontWeight: 500,
        fontSize: "1.6rem",
        letterSpacing: "0.15px",
      },
      subtitle2: {
        fontWeight: 500,
        fontSize: "1.4rem",
        letterSpacing: "0.1px",
      },
      button: {
        fontWeight: 500,
        fontSize: "1.4rem",
        letterSpacing: "1px",
      },
      caption: {
        color: palette.grey[600],
        fontWeight: 400,
        fontSize: "1.2rem",
        letterSpacing: "0.4px",
      },
      overline: {
        fontWeight: 500,
        fontSize: "1.2rem",
        letterSpacing: "1.5px",
      },
    },
    shape: {
      borderRadius: 0,
    },
    charts: {
      seriesColors: {
        dark: [
          "#0B7AC6",
          "#017D74",
          "#60A41F",
          "#0B3846",
          "#6A0341",
          "#C0004B",
          "#F57D00",
          "#FEBC00",
        ],
        main: [
          "#2399E5",
          "#009A92",
          "#85C832",
          "#2C6173",
          "#8D004B",
          "#E50050",
          "#FF9900",
          "#FCE400",
        ],
        light: [
          "#67B7EB",
          "#4EB9B5",
          "#ACD975",
          "#47849B",
          "#C0004B",
          "#EF5A83",
          "#FFB84D",
          "#FFEF67",
        ],
      },
    },
    overrides: {
      MuiTooltip: {
        tooltip: {
          padding: muiDefaults.spacing(2),
          fontSize: 12,
        },
      },
      MuiListItemIcon: {
        root: {
          minWidth: muiDefaults.spacing(5),
        },
      },
      MuiFormLabel: {
        root: {
          "&$focused": {
            color: palette.secondary.main,
          },
        },
      },
      MuiInputLabel: {
        root: {
          "&$focused": {
            color: palette.secondary.main,
          },
        },
      },
      MuiInput: {
        underline: {
          "&:after": {
            borderBottom: `2px solid ${palette.secondary.main}`,
          },
        },
      },
      MuiPaper: {
        elevation2: {
          [muiDefaults.breakpoints.down("sm")]: {
            boxShadow: muiDefaults.shadows[1],
          },
        },
      },
    },
  };

  return createMuiTheme({ ...themeOptions, ...options });
}

export const saveris1Theme = createSaveris1Theme();
