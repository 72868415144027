import { useContext } from "react";

import { Context, IContext } from "../context/fullscreenProvider";

const useFullscreen = (): IContext => {
  const context: IContext = useContext(Context);
  return context;
};

export default useFullscreen;
