import * as React from "react";
import List from "@material-ui/core/List";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import subscribeChanges from "./subscribe-changes";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MoreIcon from "@material-ui/icons/KeyboardArrowRight";

import NoAlarms from "./NoAlarms";
import AlarmList from "./AlarmList";
import useStyles from "./AlarmNotifications.styles";
import PageLoadingIndicator from "../PageLoadingIndicator";
import { useGetAlarmsQuery } from "../../generated/graphql";

interface Props {
  onClose?: () => void;
  dataTestIds?: {
    root: string;
    empty: string;
    item: string;
  };
}

const AlarmNotifications: React.FunctionComponent<Props> = ({ dataTestIds, onClose }) => {
  const limit = 5;
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const { data, loading, refetch } = useGetAlarmsQuery({
    variables: { limit },
    fetchPolicy: "no-cache",
  });

  subscribeChanges(refetch, { limit, offset: 0 });

  if (loading) {
    return <PageLoadingIndicator classes={{ root: classes.loading }} />;
  }

  if (data && data.alarms && data.alarms.result && data.alarms.result[0]) {
    return (
      <div className={classes.root} data-testid={dataTestIds ? dataTestIds.root : undefined}>
        <List className={classes.list}>
          <AlarmList
            compact
            onClose={onClose}
            alarms={data.alarms.result}
            dataTestId={dataTestIds ? dataTestIds.item : undefined}
          />
          {data.alarms.total > limit && (
            <>
              <Divider />
              <ListItem
                button
                onClick={() => {
                  history.push("/alarms");
                  if (onClose) onClose();
                }}
              >
                <ListItemText
                  className={classes.showMoreText}
                  primary={t("alarmCenter.showMore")}
                />
                <ListItemIcon className={classes.showMoreIcon}>
                  <MoreIcon />
                </ListItemIcon>
              </ListItem>
            </>
          )}
        </List>
      </div>
    );
  }

  if (!loading && (data && data.alarms && (!data.alarms.result || !data.alarms.result.length))) {
    return (
      <NoAlarms
        classes={{ root: classes.noAlarm }}
        dataTestId={dataTestIds ? dataTestIds.empty : undefined}
      />
    );
  }
  return null;
};

export default AlarmNotifications;
