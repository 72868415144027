import { ENDPOINT_LSA_LOGIN, ENDPOINT_LSA_LOGOUT, ENDPOINT_REFRESH_JWT_TOKEN } from "../config";

interface TokenData {
  jwtToken: string;
  jwtExpiresAt: number;
  refreshToken: string;
  refreshExpiresAt: number;
  userId: string;
}

export class UserEmptyError extends Error {
  constructor(message: string = "Enter your user name.") {
    super(message);
    this.name = "UserEmptyError";
  }
}

export class PasswordEmptyError extends Error {
  constructor(message: string = "Enter your password.") {
    super(message);
    this.name = "PasswordEmpty";
  }
}

export class WrongCredentialsError extends Error {
  constructor(message: string = "Wrong user or password") {
    super(message);
    this.name = "WrongCredentials";
  }
}

export class NetworkError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "NetworkError";
  }
}

class Auth {
  private static instance: Auth;
  private jwtToken?: string;
  private jwtExpiresAt?: number;
  private timer?: NodeJS.Timeout;

  static getInstance() {
    if (!Auth.instance) {
      Auth.instance = new Auth();
    }
    return Auth.instance;
  }

  public async isAuthenticated() {
    if (this.checkToken()) return true;
    try {
      await this.refreshJwtToken();
      return true;
    } catch (e) {
      return false;
    }
  }

  public async login(username: string, password: string) {
    if (!username) throw new UserEmptyError();
    if (!password) throw new PasswordEmptyError();

    try {
      const token = await this.requestLogin(username, password);
      if (token) {
        this.clearJwtRefreshTimer();
        const { jwtToken, jwtExpiresAt, refreshToken, refreshExpiresAt } = token;
        if (jwtToken) {
          this.jwtToken = jwtToken;
          this.jwtExpiresAt = jwtExpiresAt;
          this.persistRefreshToken(refreshToken, refreshExpiresAt);
          this.startJwtRefreshTimer();
          return true;
        } else {
          this.invalidateTokens();
          throw new WrongCredentialsError();
        }
      }
    } catch (e) {
      this.invalidateTokens();
      throw e;
    }
    return false;
  }

  public async logout() {
    await fetch(ENDPOINT_LSA_LOGOUT);
    this.invalidateTokens();
  }

  public getJwtToken() {
    return this.jwtToken;
  }

  private async requestLogin(username: string, password: string): Promise<TokenData | null> {
    const response = await fetch(ENDPOINT_LSA_LOGIN, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Basic ${btoa(unescape(encodeURIComponent(`${username}:${password}`)))}`,
      },
      credentials: "same-origin",
    });
    if (response.ok) {
      const result = await response.json();
      return result;
    }
    if (response.status >= 500) {
      const body = await response.text();
      if (body && body.includes("InvalidCredentialsError")) {
        return null;
      }
      throw new NetworkError(response.statusText);
    }
    if (response.status >= 403) {
      throw new WrongCredentialsError();
    }
    return null;
  }

  private async requestJwtToken(token: string) {
    const response = await fetch(ENDPOINT_REFRESH_JWT_TOKEN, {
      method: "POST",
      headers: {
        token,
        "Content-Type": "application/json",
      },
    });
    if (response.ok) {
      const result = await response.json();
      return result;
    }
    if (response.status >= 500) {
      const body = await response.text();
      if (body && body.includes("InvalidCredentialsError")) {
        return null;
      }
      throw new NetworkError(response.statusText);
    }
    return null;
  }

  private getRefreshToken() {
    const refreshExpiresAt = window.sessionStorage.getItem("cpt-tokenExpiresAt");
    return {
      refreshToken: window.sessionStorage.getItem("cpt-token"),
      refreshExpiresAt: refreshExpiresAt ? parseInt(refreshExpiresAt, 10) : null,
    };
  }

  private persistRefreshToken(token: string, expiresAt: number) {
    window.sessionStorage.setItem("cpt-token", token);
    window.sessionStorage.setItem("cpt-tokenExpiresAt", expiresAt.toString());
  }

  private invalidateRefreshToken() {
    window.sessionStorage.removeItem("cpt-token");
    window.sessionStorage.removeItem("cpt-tokenExpiresAt");
  }

  private invalidateTokens() {
    this.jwtToken = undefined;
    this.jwtExpiresAt = undefined;
    this.invalidateRefreshToken();
  }

  private checkToken() {
    if (!this.jwtToken || !this.jwtExpiresAt) {
      return false;
    }
    if (this.jwtExpiresAt < new Date().getTime()) {
      this.jwtToken = undefined;
      this.jwtExpiresAt = undefined;
      return false;
    }
    return true;
  }

  private startJwtRefreshTimer() {
    if (this.jwtExpiresAt) {
      const refreshIn = this.jwtExpiresAt - 10000 - new Date().getTime();
      this.timer = setTimeout(this.refreshJwtToken.bind(this), refreshIn);
    }
  }

  private clearJwtRefreshTimer() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = undefined;
    }
  }

  private async refreshJwtToken() {
    const { refreshToken, refreshExpiresAt } = this.getRefreshToken();
    this.clearJwtRefreshTimer();
    if (!refreshToken || !refreshExpiresAt) throw new Error("no refresh token");
    if (refreshExpiresAt < new Date().getTime()) {
      this.invalidateRefreshToken();
      throw new Error("refresh token expired");
    }
    const res = await this.requestJwtToken(refreshToken);
    if (!res) {
      throw new Error("no refresh token received");
    }
    this.jwtToken = res.jwtToken;
    this.jwtExpiresAt = res.jwtExpiresAt;
    this.startJwtRefreshTimer();
  }
}

export default Auth.getInstance();
