import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(
  (theme) => ({
    root: {
      maxHeight: `calc(100vh - 10vh)`,
      overflow: "hidden",
      overflowY: "scroll",
    },
    list: {
      paddingBottom: 0,
      minWidth: 420,
    },
    loading: {
      minWidth: 420,
      minHeight: 200,
    },
    showMoreIcon: {
      minWidth: "auto",
    },
    showMoreText: {
      fontSize: theme.typography.body2.fontSize,
      textAlign: "right",
    },
    noAlarm: {
      minWidth: 420,
    },
  }),
  { name: "AlarmCenter_AlarmNotifications" }
);
