import * as React from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, matchPath } from "react-router-dom";
import { renderRoutes, RouteConfigComponentProps } from "react-router-config";

import NoAccess from "./NoAccess";
import MainMenu from "./MainMenu";
import useTree from "../hooks/useTree";
import useUser from "../hooks/useUser";
import MainToolbar from "./MainToolbar";
import useStyles from "./PageLayout.styles";
import ErrorBoundary from "./ErrorBoundary";
import useFullscreen from "../hooks/useFullscreen";
import { TreeNode, Tree } from "../context/treeProvider";
import PageLoadingIndicator from "./PageLoadingIndicator";

interface Props extends RouteComponentProps, RouteConfigComponentProps {
  dataTestId?: string;
}

const PageLayout: React.FunctionComponent<Props> = ({ route, dataTestId }) => {
  const user = useUser();
  const classes = useStyles();
  const { views } = useTree();
  const { t } = useTranslation();
  const { isFullscreen } = useFullscreen();
  const [isDrawerOpen, setIsDrawerOpen] = React.useState<boolean>(false);
  const isAlarmCenter = !!matchPath(window.location.pathname, {
    path: "/alarms",
  });
  const isSecureZones = !!matchPath(window.location.pathname, { path: "/secure-zones" });

  const menu =
    !isAlarmCenter && !isSecureZones ? (
      <MainMenu
        isOpen={isDrawerOpen}
        tree={(views as unknown) as Tree<TreeNode>}
        onClose={() => setIsDrawerOpen(false)}
      />
    ) : null;

  const contentClass =
    user.roles.length === 0 || (views && !views.getRoot())
      ? classes.contentWithoutSidebar
      : isAlarmCenter || isSecureZones
      ? classes.contentWithoutSidebar
      : isFullscreen
      ? classes.contentWithoutSidebar
      : classes.contentWithSidebar;

  const backText = !isAlarmCenter ? t("global.backHome") : undefined;

  return (
    <>
      <MainToolbar
        backText={backText}
        isSettings={isAlarmCenter || isSecureZones}
        onToggleDrawer={() => setIsDrawerOpen(!isDrawerOpen)}
      />
      {user.roles.length > 0 && views && !!views.getRoot() && route ? menu : null}
      <main className={contentClass} data-testid={dataTestId}>
        <div className={classes.toolbarSpacer} />
        <ErrorBoundary>
          <React.Suspense fallback={<PageLoadingIndicator />}>
            {(user.roles.length === 0 || (!!views && !views.getRoot())) && <NoAccess />}
            {user.roles.length > 0 && !!views && !!views.getRoot() && route
              ? renderRoutes(route.routes)
              : null}
          </React.Suspense>
        </ErrorBoundary>
      </main>
    </>
  );
};

export default PageLayout;
