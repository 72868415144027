import * as React from "react";
import { useHistory } from "react-router-dom";
import ListItem from "@material-ui/core/ListItem";

import useStyles from "./AlarmListItemLink.styles";

interface Props {
  to: string | null;
  selected?: boolean;
  style?: Object;
  noSelect?: boolean;
  onClick?: () => void;
  dataTestId?: string;
  classes?: any;
}

const AlarmListItemLink: React.FunctionComponent<Props> = ({
  to,
  selected,
  noSelect,
  dataTestId,
  ...rest
}) => {
  const classes = useStyles(rest);
  const history = useHistory();

  if (to) {
    return (
      <ListItem
        button
        className={classes.root}
        data-testid={dataTestId}
        selected={noSelect ? false : selected}
        {...rest}
        onClick={() => {
          history.push(to);
          if (rest.onClick) rest.onClick();
        }}
      />
    );
  }
  return <ListItem button className={classes.root} data-testid={dataTestId} {...rest} />;
};

export default AlarmListItemLink;
