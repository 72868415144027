import * as R from "ramda";
import * as React from "react";
import { useForm } from "react-hook-form";
import Input from "@material-ui/core/Input";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";
import InputLabel from "@material-ui/core/InputLabel";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import FormHelperText from "@material-ui/core/FormHelperText";
import CircularProgress from "@material-ui/core/CircularProgress";

interface Props {
  classes: any;
  error?: Error;
  loading: boolean;
  dataTestId?: string;
  values?: { [k: string]: string };
  alarmInformation?: React.ReactNode;
  onNext: (values: { [k: string]: string }) => void;
  onCancel: () => void;
}

const Step2: React.FunctionComponent<Props> = ({
  error,
  loading,
  values,
  alarmInformation,
  onNext,
  onCancel,
  classes,
  dataTestId,
}) => {
  const { register, handleSubmit, errors } = useForm();
  const onSubmit = (data: any): void => {
    onNext(data);
  };
  const { t } = useTranslation();
  const [count, setCount] = React.useState(values ? values.description : 0);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DialogContent data-testid={dataTestId ? dataTestId : undefined}>
        <div className={classes.stepCounter}>
          <Typography variant="caption">
            {t("acknowledgement.step", { current: 2, total: 2 })}
          </Typography>
        </div>
        <div className={classes.description}>
          <Typography variant="body2">{t("acknowledgement.enterAlarmCauseMessage")}</Typography>
        </div>
        {!!alarmInformation && alarmInformation}
        {error && error.message === "already pending" && (
          <div className={classes.validationError}>
            <Typography variant="body2" color="error">
              {t("acknowledgement.acknowledgement.alarmAcknowledgedMessage")}
            </Typography>
          </div>
        )}
        {error && (error.message === "permission denied" || error.message === "access denied") && (
          <div className={classes.validationError}>
            <Typography variant="body2" color="error">
              {t("acknowledgement.acknowledgedNotAllowedMessage")}
            </Typography>
          </div>
        )}
        <div className={classes.form}>
          <FormControl fullWidth error={!!errors.description ? true : false}>
            <InputLabel>{t("acknowledgement.description")}</InputLabel>
            <Input
              multiline
              autoFocus
              inputProps={{
                maxLength: 256,
              }}
              rows="6"
              type="text"
              name="description"
              placeholder={t("acknowledgement.description")}
              inputRef={register({
                required: true,

                validate: (value: string) => R.trim(value).length > 0,
              })}
              onChange={(evt) => setCount(evt.target.value.length)}
              defaultValue={values ? values.description : ""}
            />
            <FormHelperText id="component-error-text">
              {count}/256
              {!!errors.description && ` - ${t("acknowledgement.emptyDescription")}`}
            </FormHelperText>
          </FormControl>
        </div>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button variant="text" onClick={onCancel}>
          {t("acknowledgement.back")}
        </Button>
        <Button variant="contained" color="primary" type="submit" disabled={loading}>
          {t("acknowledgement.acknowledge")}
        </Button>
      </DialogActions>
    </form>
  );
};

export default Step2;
