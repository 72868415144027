import * as React from "react";
import useStyles from "./index.styles";
import Dialog from "@material-ui/core/Dialog";
import { useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Theme } from "@material-ui/core/styles/createMuiTheme";

import Step1 from "./Step1";
import Step2 from "./Step2";
import { Steps } from "./index";
import AcknowledgmentTitle from "./AcknowledgmentTitle";

interface Props {
  step: Steps;
  open: boolean;
  loading: boolean;
  alarmInformation: JSX.Element;
  dataTestIds?: {
    dialog: string;
    step1: string;
    step2: string;
    alarmInfo: string;
  };
  onNext: (step: 1 | 2, values: { [k: string]: string }) => void;
  onClose: (step?: 1 | 2) => void;
  onCancel: (step?: 1 | 2) => void;
}

const AcknowledgementDialog: React.FunctionComponent<Props> = ({
  alarmInformation,
  loading,
  open,
  step,
  onNext,
  onClose,
  onCancel,
  dataTestIds,
}) => {
  const classes = useStyles();
  const theme = useTheme<Theme>();
  const { t } = useTranslation();
  const matches = useMediaQuery(theme.breakpoints.down("sm"), { noSsr: true });

  return (
    <Dialog
      data-testid={dataTestIds ? dataTestIds.dialog : undefined}
      maxWidth="sm"
      onClose={() => onClose()}
      fullScreen={matches}
      open={open}
      classes={{
        paper: classes.root,
      }}
    >
      <AcknowledgmentTitle onClose={() => onClose()}>
        {t("acknowledgement.headline")}
      </AcknowledgmentTitle>
      {step.index === 1 ? (
        <Step2
          dataTestId={dataTestIds ? dataTestIds.step2 : undefined}
          classes={classes}
          loading={loading}
          error={step.error}
          values={step.values[1]}
          alarmInformation={alarmInformation}
          onNext={(values) => onNext(2, values)}
          onCancel={() => onCancel(2)}
        />
      ) : (
        <Step1
          dataTestId={dataTestIds ? dataTestIds.step1 : undefined}
          classes={classes}
          loading={loading}
          error={step.error}
          values={step.values[0]}
          alarmInformation={alarmInformation}
          onNext={(values) => onNext(1, values)}
          onCancel={() => onCancel(1)}
        />
      )}
    </Dialog>
  );
};

export default AcknowledgementDialog;
