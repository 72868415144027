import * as R from "ramda";
import { Moment } from "moment";
import { TFunction } from "i18next";

import { TestoUnitsEnum } from "../components/Value";
import { Alarm, AlarmEntry, SlotId } from "../generated/graphql";
import { AlarmTagEnum, AlarmEventsEnum } from "../context/alarmProvider";

export type AlarmCounts = {
  errors: number;
  warnings: number;
};

export const sortSlotIdBySeverity = (slotIds: SlotId[], alarms: Alarm[] | null): SlotId[] => {
  if (alarms) {
    const sIds = R.sort<SlotId>((slotA, slotB) => {
      const aAlarms = alarms.filter(
        (a) => a.slotId === slotA.slotId && a.projectId === slotA.projectId
      );
      const bAlarms = alarms.filter(
        (a) => a.slotId === slotB.slotId && a.projectId === slotB.projectId
      );
      const sorted = sortAlarmsBySeverity([...aAlarms, ...bAlarms]);

      if (!sorted || !sorted.length) {
        return 0;
      }

      if (sorted[0].projectId === slotA.projectId && sorted[0].slotId === slotA.slotId) {
        return -1;
      } else {
        return 1;
      }
    })(slotIds);
    return sIds;
  }
  return slotIds;
};

export const sortAlarmsByTime = (alarms: Alarm[] | null): Alarm[] | null => {
  if (alarms && alarms.length) {
    return R.sort<Alarm>((a, b) => {
      const aTime = a.timestamp || 0;
      const bTime = b.timestamp || 0;
      return aTime > bTime ? -1 : 1;
    })(alarms);
  }
  return alarms;
};

export const sortAlarmsBySeverity = (alarms: Alarm[]): Alarm[] =>
  R.sort<Alarm>((a, b) =>
    getOrder([a.severity!, b.severity!], [a.timestamp!, b.timestamp!], [0, 0])
  )(alarms.filter((s) => (s.severity || 0) > 0));

export const getOrder = (
  serverity: [number, number],
  latestAlarmTime: [number, number],
  latestUpdateTime: [number, number]
) => {
  if (serverity[0] > serverity[1]) {
    return -1;
  }
  if (serverity[0] < serverity[1]) {
    return 1;
  }
  if (latestAlarmTime[0] > latestAlarmTime[1]) {
    return -1;
  }
  if (latestAlarmTime[0] < latestAlarmTime[1]) {
    return 1;
  }
  if (latestUpdateTime[0] > latestUpdateTime[1]) {
    return -1;
  }
  if (latestUpdateTime[0] < latestUpdateTime[1]) {
    return 1;
  }
  return 0;
};

export const getClassFromSeverity = (
  severity: number | null | undefined,
  errorClass: string,
  warningClass: string,
  okClass?: string
) => {
  if (severity === 2) return errorClass;
  if (severity === 1) return warningClass;
  return okClass ? okClass : undefined;
};

export const filterActiveUpperLowerCondition = (
  entry: AlarmEntry,
  alarmTs: number,
  unit: TestoUnitsEnum,
  startDate: Moment,
  endDate: Moment
) => {
  if (!entry.active) return false;
  if (!entry.condition || !entry.condition.limit) return false;
  if (alarmTs < startDate.valueOf()) return false;
  if (alarmTs > endDate.valueOf()) return false;
  if (
    (entry.type === AlarmTagEnum.LOWER_LIMIT ||
      entry.type === AlarmTagEnum.UPPER_LIMIT ||
      entry.type === AlarmTagEnum.UPPER_LIMIT_WARNING ||
      entry.type === AlarmTagEnum.LOWER_LIMIT_WARNING) &&
    unit === entry.condition.limit.unit
  ) {
    return true;
  }
  return false;
};

export const getLimitText = (entry: AlarmEntry, t: TFunction) => {
  switch (entry.type) {
    case AlarmTagEnum.LOWER_LIMIT:
      return t("alarms.lowerLimit");
    case AlarmTagEnum.UPPER_LIMIT:
      return t("alarms.upperLimit");
    case AlarmTagEnum.LOWER_LIMIT_WARNING:
      return t("alarms.lowerLimitWarning");
    case AlarmTagEnum.UPPER_LIMIT_WARNING:
      return t("alarms.upperLimitWarning");
  }
};

export const getLimitId = (entry: AlarmEntry) => {
  switch (entry.type) {
    case AlarmTagEnum.LOWER_LIMIT:
      return "LOWER_LIMIT";
    case AlarmTagEnum.UPPER_LIMIT:
      return "UPPER_LIMIT";
    case AlarmTagEnum.LOWER_LIMIT_WARNING:
      return "LOWER_LIMIT_WARNING";
    case AlarmTagEnum.UPPER_LIMIT_WARNING:
      return "UPPER_LIMIT_WARNING";
  }
};

export const getEventString = (event: number | null | undefined, t: TFunction) => {
  switch (event) {
    case AlarmEventsEnum.LOW_BATTERY:
      return t("alarms.lowBattery");
    case AlarmEventsEnum.CONNECTION_STATUS:
      return t("alarms.connectionStatus");
    case AlarmEventsEnum.CALIBRATION_DATE:
      return t("alarms.calibrationDate");
    case AlarmEventsEnum.LOW_MEMORY:
      return t("alarms.lowMemory");
    case AlarmEventsEnum.COMMUNICATION_APP:
      return t("alarms.comunicationApp");
    case AlarmEventsEnum.ALARM_SCHEDULE_STATE:
      return t("alarms.alarmScheduleState");
    case AlarmEventsEnum.PWRSPLY:
      return t("alarms.powerSupply");
    case AlarmEventsEnum.SENSOR_ERROR:
      return t("alarms.sensorError");
    case AlarmEventsEnum.GSM_NOT_AVAILABLE:
      return t("alarms.gsmNotAvailable");
    case AlarmEventsEnum.GSM_QUEUE_FULL:
      return t("alarms.gsmQueueFull");
    case AlarmEventsEnum.BATTERY_DEFECT:
      return t("alarms.batteryDefect");
    case AlarmEventsEnum.MEMORY_FULL:
      return t("alarms.memoryFull");
    case AlarmEventsEnum.GSM_MODUL_FAILED:
      return t("alarms.gsmModuleFailed");
  }
};

class AlarmCount {
  private static instance: AlarmCount;
  private _count: number | null = null;

  static getInstance() {
    if (!AlarmCount.instance) {
      AlarmCount.instance = new AlarmCount();
    }
    return AlarmCount.instance;
  }

  public set count(val: number | null) {
    this._count = val;
  }

  public get count(): number | null {
    return this._count;
  }
}

export const AlarmCounter = AlarmCount.getInstance();
