import * as React from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { onError } from "@apollo/client/link/error";
import { WebSocketLink } from "@apollo/client/link/ws";
import { setContext } from "@apollo/client/link/context";
import { getMainDefinition } from "@apollo/client/utilities";
import { SubscriptionClient } from "subscriptions-transport-ws";
import { ApolloProvider, ApolloClient, InMemoryCache, HttpLink, split } from "@apollo/client";

import Auth from "../../utils/auth";
import useAuth from "../../hooks/useAuth";

const GraphQL: React.FunctionComponent = ({ children }) => {
  const { logout } = useAuth();
  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const showNetworkError = (
    variant: "default" | "error" | "success" | "warning" | "info" = "error",
    persist: boolean = true
  ) => {
    enqueueSnackbar(t("main.networkError"), {
      key: "networkError",
      preventDuplicate: true,
      variant,
      persist,
    });
  };

  const showGeneralError = (
    message: string,
    variant: "default" | "error" | "success" | "warning" | "info" = "error",
    persist: boolean = true
  ) => {
    enqueueSnackbar(t("main.generalError", { message }), {
      key: "generalError",
      preventDuplicate: true,
      variant,
      persist,
    });
  };

  const inMemoryCache = new InMemoryCache({
    typePolicies: {
      Slot: {
        keyFields: ["projectId", "slotId"],
      },
      SlotId: {
        keyFields: ["projectId", "slotId"],
      },
      ViewItem: {
        keyFields: ["id"],
      },
      View: {
        keyFields: ["id"],
      },
      SecureZone: {
        keyFields: ["id"],
      },
      Alarm: {
        keyFields: ["ticketId", "projectId"],
      },
    },
  });

  const httpLink = new HttpLink({
    uri: "/graphql",
    credentials: "omit",
  });

  const authLink = setContext((_, { headers }) => {
    const token = Auth.getJwtToken();
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      },
    };
  });

  const wsClient = new SubscriptionClient(
    `${window.location.protocol === "https:" ? "wss" : "ws"}://${window.location.host}/graphql`,
    {
      reconnect: true,
      inactivityTimeout: 30000,
      connectionParams: {
        authorization: `Bearer ${Auth.getJwtToken()}`,
      },
    }
  );

  wsClient.onError(async (event) => {
    try {
      const response = await fetch("/health");
      if (response.ok) {
        const result = await response.json();
        if (result.status === "fail") {
          showNetworkError();
        }
        if (result.status === "warn" && result.output) {
          showNetworkError("warning", false);
        }
      } else {
        if (response.status >= 500) {
          showGeneralError(response.statusText);
        }
      }
    } catch (e) {
      showNetworkError();
    }
  });

  wsClient.onReconnected(() => {
    closeSnackbar();
  });

  const wsLink = new WebSocketLink(wsClient);

  const link = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === "OperationDefinition" && definition.operation === "subscription";
    },
    wsLink,
    authLink.concat(httpLink)
  );

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path, extensions }) => {
        if (extensions && extensions.code === "UNAUTHENTICATED") {
          logout();
        }
        if (message && message.includes("ECONNREFUSED")) {
          showNetworkError();
        }
        if (message) {
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          );
        }
      });

    if (networkError) {
      if ("statusCode" in networkError && networkError.statusCode >= 500) {
        showNetworkError();
      }
    }
  });

  const client = new ApolloClient({
    link: errorLink.concat(link),
    cache: inMemoryCache,
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default GraphQL;
