import * as React from "react";
import Grid from "@material-ui/core/Grid";

import AlarmIcon from "../Alarm/Icon";
import AlarmTrigger from "../Alarm/Trigger";
import { Alarm } from "../../generated/graphql";
import useStyles from "./AlarmInformation.styles";
import Typography from "@material-ui/core/Typography";
import { Time } from "../Value";
import { getClassFromSeverity } from "../../utils/alarms";

interface Props {
  alarm: Alarm;
  deviceName?: string | null;
  dataTestId?: string;
}

const AlarmInformation: React.FunctionComponent<Props> = ({ alarm, deviceName, dataTestId }) => {
  const classes = useStyles();
  const cc = getClassFromSeverity(alarm.severity, classes.error, classes.warning);

  return (
    <Grid container item className={classes.root} data-testid={dataTestId ? dataTestId : undefined}>
      <Grid container item xs={12} alignItems="center" className={classes.alarm}>
        {!!deviceName ? (
          <>
            <Grid item className={classes.icon}>
              <AlarmIcon alarm={alarm} />
            </Grid>
            <Grid item>
              <Typography className={classes.timestamp}>{deviceName}</Typography>
              <Time time={alarm.timestamp} className={classes.timestampSmall} />
            </Grid>
          </>
        ) : (
          <>
            <Grid item className={classes.icon}>
              <AlarmIcon alarm={alarm} />
            </Grid>
            <Grid item>
              <Time time={alarm.timestamp} className={classes.timestamp} />
            </Grid>
          </>
        )}
      </Grid>
      <Grid container item xs={12}>
        <Grid item className={classes.trigger}>
          <AlarmTrigger alarm={alarm} colorClass={cc} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AlarmInformation;
