import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";
import * as React from "react";

const Temperature: React.FunctionComponent<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path d="M8,13.2571621 L8,4.5 C8,2.56700338 9.56700338,1 11.5,1 C13.4329966,1 15,2.56700338 15,4.5 L15,13.2571621 C16.2215033,14.2659538 17,15.7920631 17,17.5 C17,20.5375661 14.5375661,23 11.5,23 C8.46243388,23 6,20.5375661 6,17.5 C6,15.7920631 6.77849667,14.2659538 8,13.2571621 Z M11.5,3 C10.6715729,3 10,3.67157288 10,4.5 L10,10 L13,10 L13,4.5 C13,3.67157288 12.3284271,3 11.5,3 Z M16.5,3 L20.5,3 C20.7761424,3 21,3.22385763 21,3.5 C21,3.77614237 20.7761424,4 20.5,4 L16.5,4 C16.2238576,4 16,3.77614237 16,3.5 C16,3.22385763 16.2238576,3 16.5,3 Z M16.5,5 L20.5,5 C20.7761424,5 21,5.22385763 21,5.5 C21,5.77614237 20.7761424,6 20.5,6 L16.5,6 C16.2238576,6 16,5.77614237 16,5.5 C16,5.22385763 16.2238576,5 16.5,5 Z M16.5,7 L20.5,7 C20.7761424,7 21,7.22385763 21,7.5 C21,7.77614237 20.7761424,8 20.5,8 L16.5,8 C16.2238576,8 16,7.77614237 16,7.5 C16,7.22385763 16.2238576,7 16.5,7 Z" />{" "}
  </SvgIcon>
);

export default Temperature;
