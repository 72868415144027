import clsx from "clsx";
import * as React from "react";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import AppBar from "@material-ui/core/AppBar";
import { useTheme } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import BackIcon from "@material-ui/icons/ArrowBack";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Theme } from "@material-ui/core/styles/createMuiTheme";

import Logo from "../Logo";
import Alarms from "./Alarms";
import UserMenu from "./UserMenu";
import useStyles from "./index.styles";
import DrawerToggle from "./DrawerToggle";
import useFullscreen from "../../hooks/useFullscreen";

interface Props {
  isSettings: boolean;
  backText?: string;
  onToggleDrawer?: () => void;
  dataTestIds?: {
    root: string;
    logo: string;
    notifications: {
      button: string;
      root: string;
      empty: string;
      item: string;
    };
    userMenu: {
      button: string;
      root: string;
    };
  };
}

const MainToolbar: React.FunctionComponent<Props> = ({
  isSettings,
  backText,
  onToggleDrawer,
  dataTestIds,
}) => {
  const classes = useStyles();
  const { isFullscreen } = useFullscreen();
  const theme = useTheme<Theme>();
  const matches = useMediaQuery(theme.breakpoints.down("sm"), { noSsr: true });

  const isTemporaryDrawer = matches || isFullscreen;

  return (
    <AppBar
      position="fixed"
      data-testid={dataTestIds ? dataTestIds.root : undefined}
      className={clsx(classes.root, { [classes.settingsColor]: isSettings })}
    >
      <Toolbar>
        <Grid container justify="space-between" alignItems="center">
          {!isSettings && isTemporaryDrawer && (
            <Grid item className={classes.menuButton}>
              <DrawerToggle onToggle={onToggleDrawer} />
            </Grid>
          )}
          <Grid item className={classes.grow}>
            <Link to="/" className={classes.link}>
              <Logo
                className={clsx(classes.logo, {
                  [classes.hidden]: isSettings,
                })}
                imageClassName={classes.logoImg}
                dataTestId={dataTestIds ? dataTestIds.logo : undefined}
              />
              <Typography
                noWrap
                variant="body1"
                className={clsx(classes.back, {
                  [classes.hidden]: !isSettings,
                })}
              >
                <BackIcon className={classes.backIcon} /> {backText}
              </Typography>
            </Link>
          </Grid>
          <Grid item>
            <Alarms
              isSettings={isSettings}
              dataTestIds={dataTestIds ? dataTestIds.notifications : undefined}
            />
            <UserMenu
              isSettings={isSettings}
              dataTestIds={dataTestIds ? dataTestIds.userMenu : undefined}
            />
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default MainToolbar;
