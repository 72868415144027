import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";
import * as React from "react";

const GsmNotAvailable: React.FunctionComponent<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path d="M13,14.3v8.3h-2v-8.3C10.1,13.9,9.5,13,9.5,12c0-1.4,1.1-2.5,2.5-2.5s2.5,1.1,2.5,2.5C14.5,13,13.9,13.9,13,14.3z M12,13c0.6,0,1-0.4,1-1s-0.4-1-1-1s-1,0.4-1,1S11.4,13,12,13z" />
    <path
      style={{ opacity: 0.19 }}
      d="M12,1C5.9,1,1,5.9,1,12h2c0-5,4-9,9-9s9,4,9,9h2C23,5.9,18.1,1,12,1z"
    />
  </SvgIcon>
);

export default GsmNotAvailable;
