import * as React from "react";

import Login from "./Login";
import useAuth from "../../hooks/useAuth";

interface Props {
  children: React.ReactNode;
}

const Authenticated: React.FunctionComponent<Props> = ({ children }) => {
  const { authenticated } = useAuth();
  if (authenticated) {
    return <>{children}</>;
  }
  return <Login />;
};

export default Authenticated;
