import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";
import * as React from "react";

const MemoryLow: React.FunctionComponent<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path
      fillOpacity=".3"
      d="M18,2c1.1,0,2,0.9,2,2v13H4l0-9l6-6H18z M12,8V4h-2v4H12z M15,8V4h-2v4H15z M18,8V4h-2v4 H18z"
    />
    <g>
      <path d="M4,17l0,3c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2v-3H4z" />
    </g>
  </SvgIcon>
);

export default MemoryLow;
