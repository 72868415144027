import { useContext } from "react";

import { UserContext, User } from "../context/userProvider";

interface UseUser {
  (): User;
}

const useUser: UseUser = (): User => {
  const userContext: User = useContext(UserContext);
  return userContext;
};

export default useUser;
