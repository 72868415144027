import * as React from "react";
import { useForm } from "react-hook-form";
import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";
import Button from "@material-ui/core/Button";
import LockIcon from "@material-ui/icons/Lock";
import PersonIcon from "@material-ui/icons/Person";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import Typography from "@material-ui/core/Typography";
import Visibility from "@material-ui/icons/Visibility";
import FormControl from "@material-ui/core/FormControl";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputAdornment from "@material-ui/core/InputAdornment";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useTranslation } from "react-i18next";

interface Props {
  classes: any;
  error?: Error;
  loading: boolean;
  dataTestId?: string;
  alarmInformation?: React.ReactNode;
  values?: { [k: string]: string };
  onNext: (values: { [k: string]: string }) => void;
  onCancel: () => void;
}

const Step1: React.FunctionComponent<Props> = ({
  error,
  loading,
  values,
  alarmInformation,
  onNext,
  onCancel,
  classes,
  dataTestId,
}) => {
  const { register, handleSubmit, errors } = useForm();
  const onSubmit = (data: any): void => {
    onNext(data);
  };
  const [showPassword, setShowPassord] = React.useState<boolean>(false);
  const { t } = useTranslation();
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DialogContent data-testid={dataTestId ? dataTestId : undefined}>
        <div className={classes.stepCounter}>
          <Typography variant="caption">
            {t("acknowledgement.step", { current: 1, total: 2 })}
          </Typography>
        </div>
        <div className={classes.description}>
          <Typography variant="body2">{t("acknowledgement.authenticateMessage")}</Typography>
        </div>
        {!!alarmInformation && alarmInformation}
        {error && error.message === "validation error" && (
          <div className={classes.validationError}>
            <Typography variant="body2" color="error">
              {t("acknowledgement.acknowledgedNotAllowedMessage")}
            </Typography>
          </div>
        )}
        {error && error.message === "login error" && (
          <div className={classes.validationError}>
            <Typography variant="body2" color="error">
              {t("auth.errorWrongCredentials")}
            </Typography>
          </div>
        )}
        <div className={classes.form}>
          <Grid
            container
            item
            xs={12}
            spacing={1}
            className={classes.usernameContainer}
            alignItems={!!errors.password ? "center" : "flex-end"}
          >
            <Grid item>
              <PersonIcon />
            </Grid>
            <Grid item className={classes.inputWrapper}>
              <FormControl fullWidth error={!!errors.username ? true : false}>
                <InputLabel>{t("global.username")}</InputLabel>
                <Input
                  autoFocus
                  type={"text"}
                  name="username"
                  placeholder={t("global.username")}
                  inputRef={register({ required: true })}
                  defaultValue={values ? values.username : ""}
                />
                {!!errors.username && (
                  <FormHelperText id="component-error-text">
                    {t("auth.errorEmptyUser")}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            spacing={1}
            alignItems={!!errors.password ? "center" : "flex-end"}
          >
            <Grid item>
              <LockIcon />
            </Grid>
            <Grid item className={classes.inputWrapper}>
              <FormControl fullWidth error={!!errors.password ? true : false}>
                <InputLabel>{t("global.password")}</InputLabel>
                <Input
                  name="password"
                  placeholder={t("global.password")}
                  inputRef={register({ required: true })}
                  type={showPassword ? "text" : "password"}
                  defaultValue={values ? values.password : ""}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label={t("ariaLabel.togglePwVisibility")}
                        onClick={() => setShowPassord(!showPassword)}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {!!errors.password && (
                  <FormHelperText id="component-error-text">
                    {t("auth.errorEmptyPassword")}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
        </div>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button variant="text" onClick={onCancel}>
          {t("global.cancel")}
        </Button>
        <Button variant="contained" color="primary" type="submit" disabled={loading}>
          {t("global.next")}
        </Button>
      </DialogActions>
    </form>
  );
};

export default Step1;
