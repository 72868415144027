import clsx from "clsx";
import * as React from "react";
import { useTheme } from "@material-ui/core/styles";
import Collapse from "@material-ui/core/Collapse";
import ListItem from "@material-ui/core/ListItem";
import ExpandLess from "@material-ui/icons/ChevronRight";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { Theme } from "@material-ui/core/styles/createMuiTheme";

import useStyles from "./MenuListItem.styles";
import MenuAlarmBadge from "./MenuAlarmBadge";
import { Tree, View, TreeNode } from "../../context/treeProvider";

interface Props {
  item: TreeNode;
  tree: Tree<TreeNode>;
  activePath: string;
  openPath: string;
  depth?: number;
  onToggle: (node: TreeNode) => void;
  childrenHasChildren?: boolean;
  isLoading?: boolean;
  rootPath: string;
  dataTestIds?: {
    root: string;
    badge: string;
  };
}

const MenuListItem: React.FunctionComponent<Props> = ({
  item,
  tree,
  depth = 0,
  onToggle,
  activePath,
  openPath,
  rootPath,
  dataTestIds,
}) => {
  const classes = useStyles();
  const theme = useTheme<Theme>();
  const children = tree.getChildren(item.id);
  const open =
    depth === 0
      ? true
      : openPath && children && children.length
      ? openPath.includes(item.path)
      : false;

  const isView = (arg: any): arg is View => {
    return arg.viewItems !== undefined;
  };

  const active = activePath === item.path || (activePath === "/" && depth === 0);

  const isExpandable =
    (isView(item) && !!children && children.length > 0) || (!!children && children.length > 0);

  return (
    <>
      <ListItem
        button
        selected={active}
        className={classes.item}
        data-testid={dataTestIds ? dataTestIds.root : undefined}
        onClick={() => {
          if (onToggle) onToggle(item);
        }}
        style={{
          paddingLeft: depth > 0 ? theme.spacing(3) * depth : theme.spacing(2),
        }}
      >
        {depth > 0 && isExpandable && (
          <ListItemIcon className={classes.toggle}>
            {open ? <ExpandMore /> : <ExpandLess />}
          </ListItemIcon>
        )}
        {isView(item) ? (
          <MenuAlarmBadge item={item} dataTestId={dataTestIds ? dataTestIds.badge : undefined}>
            <ListItemText
              primary={item.name}
              className={classes.listItemText}
              classes={{ primary: clsx({ [classes.linkItemSelected]: active }) }}
            />
          </MenuAlarmBadge>
        ) : (
          <ListItemText primary={item.name} />
        )}
      </ListItem>
      {isExpandable &&
        !!children &&
        children.map((child: TreeNode) => (
          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit
            key={`view-list-item-children-${child.id}`}
          >
            <MenuListItem
              onToggle={onToggle}
              rootPath={rootPath}
              item={child}
              tree={tree}
              activePath={activePath}
              openPath={openPath}
              depth={depth + 1}
              dataTestIds={dataTestIds}
            />
          </Collapse>
        ))}
    </>
  );
};

export default MenuListItem;
