import * as React from "react";
import { useImportDoneSubscription } from "../generated/graphql";

const ImportChecker: React.FunctionComponent = () => {
  useImportDoneSubscription({
    onSubscriptionData: async () => window.location.reload(),
  });
  return null;
};

export default ImportChecker;
