import clsx from "clsx";
import * as React from "react";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import { useHistory } from "react-router-dom";
import Hidden from "@material-ui/core/Hidden";
import Button from "@material-ui/core/Button";
import Popper from "@material-ui/core/Popper";
import Divider from "@material-ui/core/Divider";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import IconButton from "@material-ui/core/IconButton";
import AccountIcon from "@material-ui/icons/AccountCircle";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import AppInfo from "../AppInfo";
import useAuth from "../../hooks/useAuth";
import useStyles from "./UserMenu.styles";
import useUser from "../../hooks/useUser";
import { useTranslation } from "react-i18next";
import LanguageSwitch from "../LanguageSwitch";
import useFullscreen from "../../hooks/useFullscreen";

interface Props {
  isSettings: boolean;
  classes?: any;
  dataTestIds?: {
    button: string;
    root: string;
  };
}

const UserMenu: React.FunctionComponent<Props> = (props) => {
  const classes = useStyles(props);
  const { isSettings, dataTestIds } = props;
  const { logout } = useAuth();
  const user = useUser();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [languageSwitchOpen, setLanguageSwitchOpen] = React.useState<boolean>(false);
  const [aboutOpen, setAboutOpen] = React.useState<boolean>(false);
  const { t } = useTranslation();
  const { isFullscreen, toggleFullscreen } = useFullscreen();

  if (user) {
    return (
      <>
        <Hidden smDown>
          <Button
            className={classes.button}
            onClick={(event) => setAnchorEl(event.currentTarget)}
            data-testid={dataTestIds ? dataTestIds.button : undefined}
          >
            <AccountIcon
              className={clsx(classes.icon, {
                [classes.brightIcon]: isSettings,
              })}
            />
            {user.displayName}
          </Button>
        </Hidden>
        <Hidden mdUp>
          <IconButton
            data-testid={dataTestIds ? dataTestIds.button : undefined}
            className={classes.button}
            onClick={(event) => setAnchorEl(event.currentTarget)}
          >
            <AccountIcon
              className={clsx(classes.icon, {
                [classes.brightIcon]: isSettings,
              })}
            />
          </IconButton>
        </Hidden>
        <Popper open={!!anchorEl} anchorEl={anchorEl} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                  <MenuList data-testid={dataTestIds ? dataTestIds.root : undefined}>
                    {user.can("admin") && (
                      <MenuItem
                        onClick={() => {
                          setAnchorEl(null);
                          history.push("/secure-zones");
                        }}
                      >
                        {t("roles.roleAssignment")}
                      </MenuItem>
                    )}
                    <MenuItem
                      onClick={() => {
                        setAnchorEl(null);
                        setLanguageSwitchOpen(true);
                      }}
                    >
                      {t("global.languages")}
                    </MenuItem>
                    <Hidden smDown>
                      <MenuItem
                        onClick={() => {
                          setAnchorEl(null);
                          toggleFullscreen();
                        }}
                      >
                        {isFullscreen ? t("global.tvModeOff") : t("global.tvModeOn")}
                      </MenuItem>
                    </Hidden>
                    <MenuItem
                      onClick={() => {
                        setAnchorEl(null);
                        setAboutOpen(true);
                      }}
                    >
                      {t("global.about")}
                    </MenuItem>
                    <Divider />
                    <MenuItem
                      onClick={() => {
                        logout();
                        history.push("/");
                        setAnchorEl(null);
                      }}
                    >
                      {t("global.logout")}
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        <LanguageSwitch onClose={() => setLanguageSwitchOpen(false)} open={languageSwitchOpen} />
        <AppInfo onClose={() => setAboutOpen(false)} open={aboutOpen} />
      </>
    );
  }
  return null;
};

export default UserMenu;
