import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(
  (theme) => ({
    root: {
      transition: `background-color ${theme.transitions.duration.standard}ms ${theme.transitions.easing.easeInOut}`,
      background: theme.palette.grey[800],
      zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
      marginRight: 20,
    },
    grow: {
      flexGrow: 1,
    },
    settingsColor: {
      background: theme.palette.secondary.main,
    },
    link: {
      position: "relative",
      textDecoration: "none",
      display: "flex",
      alignItems: "center",
    },
    logo: {
      transition: `opacity ${theme.transitions.duration.standard}ms ${theme.transitions.easing.easeInOut}`,
      height: theme.spacing(5),
    },
    logoImg: {
      maxHeight: 40,
    },
    back: {
      position: "absolute",
      display: "flex",
      alignItems: "center",
      fontSize: theme.typography.h2.fontSize,
      fontWeight: theme.typography.h2.fontWeight,
      left: 0,
      transition: `opacity ${theme.transitions.duration.standard}ms ${theme.transitions.easing.easeInOut}`,
      color: theme.palette.common.white,
    },
    hidden: {
      opacity: 0,
    },
    backIcon: {
      marginRight: theme.spacing(2),
      color: theme.palette.common.white,
    },
  }),
  { name: "MainToolbar" }
);
