import * as React from "react";
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/icons/HowToReg";
import Typography from "@material-ui/core/Typography";

import useStyles from "./NoAccess.styles";
import { useTranslation } from "react-i18next";

const NoAccess: React.FunctionComponent<{
  classes?: any;
}> = (props) => {
  const classes = useStyles(props);
  const { t } = useTranslation();
  return (
    <Grid container direction="row" alignItems="center" justify="center">
      <div className={classes.root}>
        <Grid container direction="column" alignItems="center">
          <div className={classes.circle}>
            <Icon className={classes.icon} />
          </div>
          <Typography variant="h2" className={classes.text}>
            {t("global.noAccess")}
          </Typography>
          <Typography variant="body2" className={classes.textSub}>
            {t("global.noAccessContact")}
          </Typography>
        </Grid>
      </div>
    </Grid>
  );
};

export default NoAccess;
