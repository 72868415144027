import moment from "moment";
import * as React from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useApolloClient } from "@apollo/client";

import { Alarm } from "../../generated/graphql";
import AlarmInformation from "./AlarmInformation";
import AcknowledgementDialog from "./AcknowledgementDialog";
import { AcknowledgeAlarmDocument, ValidateAcknowledgeUserDocument } from "../../generated/graphql";

interface AcknowledgmentProps {
  open: boolean;
  projectId: number | null;
  deviceName?: string | null;
  alarm: Alarm | null;
  dataTestIds?: {
    dialog: string;
    step1: string;
    step2: string;
    alarmInfo: string;
  };
  onClose: () => void;
}

export interface Steps {
  index: 0 | 1 | 2 | 3 | 4;
  error?: Error;
  values: { [k: string]: string }[];
}

const Acknowledgment: React.FunctionComponent<AcknowledgmentProps> = ({
  open,
  projectId,
  alarm,
  deviceName,
  onClose,
  dataTestIds,
}) => {
  const { t } = useTranslation();
  const client = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();
  const [step, setStep] = React.useState<Steps>({ index: 0, values: [] });
  const [loading, setLoading] = React.useState<boolean>(false);

  if (alarm) {
    const alarmInformation = (
      <AlarmInformation
        alarm={alarm}
        deviceName={deviceName}
        dataTestId={dataTestIds ? dataTestIds.alarmInfo : undefined}
      />
    );

    const onStep1Submit = async (values: { [k: string]: string }) => {
      setLoading(true);
      try {
        const { data } = await client.query({
          query: ValidateAcknowledgeUserDocument,
          fetchPolicy: "network-only",
          variables: {
            username: values.username,
            password: values.password,
          },
        });
        setLoading(false);
        if (data.validateAcknowledgeUser) {
          setStep({ index: 1, values: [values] });
        } else {
          setStep({
            index: 0,
            values: [values],
            error: new Error("validation error"),
          });
        }
      } catch (err) {
        setLoading(false);
        if (
          err.graphQLErrors &&
          err.graphQLErrors[0] &&
          err.graphQLErrors[0].message === "access denied"
        ) {
          setStep({
            index: 0,
            values: [values],
            error: new Error("login error"),
          });
        } else if (
          err.graphQLErrors &&
          err.graphQLErrors[0] &&
          err.graphQLErrors[0].message === "permission denied"
        ) {
          setStep({
            index: 0,
            values: [values],
            error: new Error("validation error"),
          });
        }
      }
    };

    const onStep1Cancel = () => {
      setStep({ index: 0, values: [] });
      onClose();
    };

    const onStep2Submit = async (values: { [k: string]: string }) => {
      setLoading(true);
      try {
        const { data } = await client.mutate({
          mutation: AcknowledgeAlarmDocument,
          variables: {
            projectId,
            ticketId: alarm.ticketId,
            username: step.values[0].username,
            password: step.values[0].password,
            comments: values.description,
            date: moment().toISOString(true),
          },
        });
        if (data.acknowledgeAlarm) {
          setStep({ index: 0, values: [] });
          onClose();
        }
      } catch (err) {
        if (
          err.graphQLErrors &&
          err.graphQLErrors[0] &&
          err.graphQLErrors[0].message === "already pending"
        ) {
          setStep({ index: 0, values: [] });
          enqueueSnackbar(t("acknowledgement.alarmPendingMessage"), {
            variant: "warning",
          });
          onClose();
        } else {
          setStep({ index: 0, values: [] });
          enqueueSnackbar(t("acknowledgement.unkownErrorMessage"), {
            variant: "error",
          });
          onClose();
        }
      } finally {
        setLoading(false);
      }
    };

    const onStep2Cancel = () => setStep({ index: 0, values: step.values });

    const onStepClose = () => {
      setStep({ index: 0, values: [] });
      onClose();
    };

    return (
      <AcknowledgementDialog
        step={step}
        open={open}
        loading={loading}
        onClose={onStepClose}
        dataTestIds={dataTestIds}
        alarmInformation={alarmInformation}
        onCancel={(stepNum) => {
          if (stepNum === 2) onStep2Cancel();
          else onStep1Cancel();
        }}
        onNext={(stepNum, values) => {
          if (stepNum === 2) onStep2Submit(values);
          else onStep1Submit(values);
        }}
      />
    );
  }
  return null;
};

export default Acknowledgment;
