import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider, StylesProvider } from "@material-ui/core/styles";

import { saveris1Theme } from "./saverisTheme";

const generateClassName = (rule: any, styleSheet: any) =>
  `${styleSheet.options.classNamePrefix}-${rule.key}`;

const Theme: React.FunctionComponent = ({ children }) => {
  return (
    <StylesProvider
      injectFirst={true}
      generateClassName={process.env.NODE_ENV === "test" ? generateClassName : undefined}
    >
      <ThemeProvider theme={saveris1Theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StylesProvider>
  );
};

export default Theme;
