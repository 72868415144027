import { useContext } from "react";

import { Context, IContext } from "../context/acknowledgementProvider";

const useAcknowledgement = (): IContext => {
  const context: IContext = useContext(Context);
  return context;
};

export default useAcknowledgement;
