import * as React from "react";

import testoLogo from "./testoLogo.png";
import testoLogoInverse from "./testoLogoInverse.png";
import { useGetLogoQuery } from "../../generated/graphql";

interface Props {
  className?: string;
  showTesto?: boolean;
  imageClassName?: string;
  inverse?: boolean;
  forceTesto?: boolean;
  dataTestId?: string;
}

const Logo: React.FunctionComponent<Props> = ({
  showTesto,
  className,
  inverse,
  forceTesto,
  imageClassName,
  dataTestId,
}) => {
  const { data, loading } = useGetLogoQuery();

  if (forceTesto) {
    return (
      <div className={className} data-testid={dataTestId}>
        <img src={inverse ? testoLogoInverse : testoLogo} alt="" className={imageClassName} />
      </div>
    );
  }

  if (!loading && data && data.logo) {
    const logo = data.logo;

    if ((!logo || !logo.length) && showTesto) {
      return null;
    }

    if (logo && logo.length && !showTesto) {
      return (
        <div className={className} data-testid={dataTestId}>
          <img src={`/image/${logo}`} alt="" className={imageClassName} />
        </div>
      );
    }
  }

  if (!loading) {
    return (
      <div className={className} data-testid={dataTestId}>
        <img src={inverse ? testoLogoInverse : testoLogo} alt="" className={imageClassName} />
      </div>
    );
  }
  return null;
};

export default Logo;
