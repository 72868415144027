import clsx from "clsx";
import * as React from "react";
import Badge from "@material-ui/core/Badge";
import Popover from "@material-ui/core/Popover";
import IconButton from "@material-ui/core/IconButton";
import NotificationsIcon from "@material-ui/icons/Notifications";

import useStyles from "./Alarms.styles";
import useAlarmsTotal from "../../hooks/useAlarmsTotal";
import AlarmNotifications from "../AlarmCenter/AlarmNotifications";

interface Props {
  isSettings?: boolean;
  dataTestIds?: {
    button: string;
    root: string;
    empty: string;
    item: string;
  };
}

const Alarms: React.FunctionComponent<Props> = ({ isSettings = false, dataTestIds }) => {
  const classes = useStyles();
  const { count } = useAlarmsTotal();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const handleClose = () => setAnchorEl(null);

  return (
    <>
      <IconButton
        data-testid={dataTestIds ? dataTestIds.button : undefined}
        onClick={(event) => setAnchorEl(event.currentTarget)}
        className={clsx(classes.icon, { [classes.brightIcon]: isSettings })}
      >
        <Badge
          badgeContent={count > 0 ? count : ""}
          classes={{
            root: classes.root,
            badge: clsx({
              [classes.error]: count > 0,
            }),
          }}
        >
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Popover
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {!!anchorEl && (
          <AlarmNotifications
            onClose={handleClose}
            dataTestIds={
              dataTestIds
                ? { root: dataTestIds.root, empty: dataTestIds.empty, item: dataTestIds.item }
                : undefined
            }
          />
        )}
      </Popover>
    </>
  );
};

export default Alarms;
