import clsx from "clsx";
import * as React from "react";
import List from "@material-ui/core/List";
import { useHistory } from "react-router";
import { CollectionEl } from "nested-sets-tree";

import useStyles from "./MenuList.styles";
import MenuListItem from "./MenuListItem";
import useTree from "../../hooks/useTree";
import { Tree, TreeNode } from "../../context/treeProvider";

interface Props {
  tree: Tree<TreeNode>;
  startDepth?: number;
  rootPath?: string;
  hasLogo?: boolean;
  dataTestIds?: {
    root: string;
    item: string;
    badge: string;
  };
}

const MenuList: React.FunctionComponent<Props> = ({
  tree,
  startDepth = 0,
  rootPath = "/view",
  hasLogo = false,
  dataTestIds,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { activePath, views, isEvaluation } = useTree();
  const [toggledPath, setToggledPath] = React.useState<string>(activePath);
  const rootView = tree.getRoot();

  const onToggle = (item: TreeNode) => {
    if (!isEvaluation && activePath === item.path && toggledPath === item.path) {
      const toggled = (views!.getParent((item as unknown) as CollectionEl)
        .results as unknown) as TreeNode[];
      if (toggled && toggled[0]) {
        setToggledPath(toggled[0].path);
      } else {
        setToggledPath("");
      }
    } else {
      setToggledPath(item.path);
      history.push(`${rootPath}${item.path}`);
    }
  };

  if (rootView) {
    return (
      <List
        component="nav"
        className={clsx(classes.root, { [classes.rootLogo]: hasLogo })}
        data-testid={dataTestIds ? dataTestIds.root : undefined}
      >
        <MenuListItem
          tree={tree}
          item={rootView}
          depth={startDepth}
          onToggle={onToggle}
          rootPath={rootPath}
          activePath={activePath}
          openPath={toggledPath}
          key={`view-list-item-${rootView.id}`}
          dataTestIds={
            dataTestIds ? { root: dataTestIds.item, badge: dataTestIds.badge } : undefined
          }
        />
      </List>
    );
  }
  return null;
};

export default MenuList;
