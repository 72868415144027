import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(
  (theme) => ({
    listItem: {
      background: theme.palette.common.white,
      color: theme.palette.text.primary,
      cursor: "pointer",
      paddingRight: theme.spacing(2),
    },
    slotName: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    timestamp: {
      fontWeight: theme.typography.fontWeightRegular,
    },
    mobileIcon: {
      paddingRight: theme.spacing(1),
    },
    compactText: {
      minWidth: 200,
    },
    trigger: {
      minWidth: "auto",
      [theme.breakpoints.down("sm")]: {
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(1),
      },
    },
    rightIcon: {
      textAlign: "left",
      [theme.breakpoints.up("md")]: {
        textAlign: "right",
      },
    },
    rightIconCompact: {
      textAlign: "right",
    },
  }),
  { name: "AlarmCenter_AlarmList" }
);
