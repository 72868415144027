import clsx from "clsx";
import * as React from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";
import LockIcon from "@material-ui/icons/Lock";
import PersonIcon from "@material-ui/icons/Person";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

import useStyles from "./Login.styles";
import useAuth from "../../hooks/useAuth";
import {
  UserEmptyError,
  PasswordEmptyError,
  WrongCredentialsError,
  NetworkError,
} from "../../utils/auth";

const Login: React.FunctionComponent = () => {
  const classes = useStyles();
  const { login, error, isLoading } = useAuth();
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const { t } = useTranslation();

  return (
    <Grid container justify="center" alignItems="center" className={classes.root}>
      <Grid item xs={12} md={5} lg={4} xl={3}>
        <Paper className={classes.paper}>
          <Typography variant="h1" className={classes.headerText}>
            {t("auth.welcomeMessage")}
          </Typography>
          <Typography variant="body2" className={clsx(classes.headerText, classes.sub)}>
            {t("auth.detailsMessage")}
          </Typography>
          {error instanceof WrongCredentialsError && (
            <Typography
              variant="body2"
              className={clsx(classes.headerText, classes.sub, classes.error)}
            >
              {t("auth.errorWrongCredentials")}
            </Typography>
          )}
          {error instanceof NetworkError && (
            <Typography
              variant="body2"
              className={clsx(classes.headerText, classes.sub, classes.error)}
            >
              {t("auth.networkError")}
            </Typography>
          )}
          <form
            onSubmit={(evt) => {
              evt.preventDefault();
              login(username, password);
            }}
          >
            <Grid container className={classes.formGrid}>
              <Grid container spacing={1} alignItems="flex-end" className={classes.fieldGrid}>
                <Grid item>
                  <PersonIcon />
                </Grid>
                <Grid item className={classes.fieldGridItem}>
                  <TextField
                    fullWidth
                    autoFocus
                    id="username"
                    label={t("global.username")}
                    value={username}
                    autoComplete="username"
                    error={error instanceof UserEmptyError}
                    onChange={(event) => setUsername(event.target.value)}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1} alignItems="flex-end" className={classes.fieldGrid}>
                <Grid item>
                  <LockIcon />
                </Grid>
                <Grid item className={classes.fieldGridItem}>
                  <TextField
                    fullWidth
                    type="password"
                    id="password"
                    label={t("global.password")}
                    value={password}
                    autoComplete="current-password"
                    error={error instanceof PasswordEmptyError}
                    onChange={(event) => setPassword(event.target.value)}
                  />
                </Grid>
              </Grid>
              <Grid item className={classes.fieldGrid}>
                <div className={classes.buttonWrapper}>
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled={isLoading}
                    className={classes.button}
                  >
                    {t("auth.signIn")}
                  </Button>
                  {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                </div>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Login;
