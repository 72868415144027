import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(
  (theme) => ({
    linkItemSelected: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    listItemText: {
      wordBreak: "break-word",
    },
    toggle: {
      minWidth: `${theme.spacing(4)}px !important`,
      marginLeft: -6,
    },
    item: {
      color: theme.palette.grey[800],
      paddingRight: theme.spacing(5),
    },
  }),
  { name: "MainMenu_MenuListItem" }
);
