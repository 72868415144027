import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(
  (theme) => ({
    autoScroll: {
      marginTop: theme.spacing(1),
    },
    logs: {
      background: theme.palette.grey[200],
      padding: theme.spacing(2),
      marginTop: theme.spacing(2),
      minWidth: 450,
      maxHeight: 300,
      overflow: "hidden",
      overflowY: "scroll",
    },
    logMessage: {
      width: "100%",
    },
  }),
  { name: "AppInfo/Log" }
);
