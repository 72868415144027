import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";
import * as React from "react";

const GsmModuleFailed: React.FunctionComponent<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path d="M7.1,11.1C7,11.4,7,11.7,7,12H5c0-0.9,0.2-1.7,0.5-2.5L3.9,8C3.3,9.2,3,10.6,3,12H1c0-2,0.5-3.9,1.5-5.5L1.2,5.3l1.4-1.4l17.5,17.5l-1.4,1.4L13,17.1v5.5h-2v-7.5L7.1,11.1z M7.8,6.4C9,5.5,10.4,5,12,5c3.9,0,7,3.1,7,7h-2c0-2.8-2.2-5-5-5c-1,0-2,0.3-2.8,0.8L7.8,6.4z M14.3,12.9l-3.2-3.2c0.3-0.1,0.6-0.2,0.9-0.2c1.4,0,2.5,1.1,2.5,2.5C14.5,12.3,14.4,12.6,14.3,12.9zM6.4,5L5,3.6C6.9,2,9.3,1,12,1c6.1,0,11,4.9,11,11h-2c0-5-4-9-9-9C9.9,3,7.9,3.7,6.4,5z" />
  </SvgIcon>
);

export default GsmModuleFailed;
