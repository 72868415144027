import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(
  (theme) => ({
    time: {
      paddingRight: theme.spacing(2),
      minWidth: 120,
    },
    trigger: {
      paddingRight: theme.spacing(2),
      minWidth: 120,
    },
    lastValue: {
      minWidth: 120,
    },
    warning: {
      color: theme.palette.warning.main,
    },
    error: {
      color: theme.palette.error.main,
    },
  }),
  { name: "Alarm_Condition" }
);
