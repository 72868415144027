import "core-js/stable";
import "regenerator-runtime/runtime";
import "whatwg-fetch";

import * as React from "react";
import * as moment from "moment";
import { render } from "react-dom";

import bootstrap from "./bootstrap";
import App from "./components";
import PageLoadingIndicator from "./components/PageLoadingIndicator";

// @ts-ignore
window.moment = moment;

async function main() {
  await bootstrap();
  const Application = (
    <React.Suspense fallback={<PageLoadingIndicator />}>
      <App />
    </React.Suspense>
  );

  const root = document.getElementById("root");
  render(Application, root);
}

main().catch((err) => {
  throw err;
});
