import * as React from "react";
import { Helmet } from "react-helmet";
import { SnackbarProvider } from "notistack";
import { useTranslation } from "react-i18next";
import { BrowserRouter } from "react-router-dom";
import { renderRoutes } from "react-router-config";

import routes from "../routes";
import useStyles from "./index.styles";
import ImportChecker from "./ImportChecker";
import GraphQL from "../context/graphqlProvider";
import Authenticated from "./Auth/Authenticated";
import UserProvider from "../context/userProvider";
import AuthProvider from "../context/authProvider";
import TreeProvider from "../context/treeProvider";
import Theme from "../context/stylesProvider/Theme";
import AlarmProvider from "../context/alarmProvider";
import FullscreenProvider from "../context/fullscreenProvider";
import AcknowledgementProvider from "../context/acknowledgementProvider";

const App: React.FunctionComponent = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Theme>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("main.title")}</title>
      </Helmet>
      <FullscreenProvider>
        <BrowserRouter>
          <SnackbarProvider
            autoHideDuration={4000}
            maxSnack={3}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            classes={{
              variantError: classes.snackbarFont,
              variantSuccess: classes.snackbarFont,
              variantInfo: classes.snackbarFont,
              variantWarning: classes.snackbarFont,
            }}
          >
            <AuthProvider>
              <Authenticated>
                <GraphQL>
                  <ImportChecker />
                  <UserProvider>
                    <AlarmProvider>
                      <TreeProvider routes={routes}>
                        <AcknowledgementProvider>{renderRoutes(routes)}</AcknowledgementProvider>
                      </TreeProvider>
                    </AlarmProvider>
                  </UserProvider>
                </GraphQL>
              </Authenticated>
            </AuthProvider>
          </SnackbarProvider>
        </BrowserRouter>
      </FullscreenProvider>
    </Theme>
  );
};

export default App;
