import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(
  theme => ({
    root: {
      marginRight: theme.spacing(1)
    },
    icon: {
      color: theme.palette.grey[400],
      transition: `
        opacity ${theme.transitions.duration.standard}ms ${theme.transitions.easing.easeInOut},
        color ${theme.transitions.duration.standard}ms ${theme.transitions.easing.easeInOut}`
    },
    brightIcon: {
      color: theme.palette.common.white,
      opacity: 0.7
    },
    error: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText
    }
  }),
  { name: "MainToolbar_Alarms" }
);
