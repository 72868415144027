import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";
import * as React from "react";

const SensorError: React.FunctionComponent<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path d="M12.9773754,11.9254957 L19.4317505,18.3798708 L18.3798708,19.4317505 L14.1382305,15.1901102 C14.0285279,16.0347167 13.445137,16.7239295 12.669287,16.9981523 L12.669287,20.1072 L11.1817043,20.1072 L11.1817043,16.9981523 C10.3189064,16.68576 9.69412174,15.8675896 9.69412174,14.9006609 L9.69412174,13.4130783 L12.3611985,13.4130783 L10.8736159,11.9254957 L9.69412174,11.9254957 L9.69412174,10.7460015 L5.23137391,6.28325366 L6.28325366,5.23137391 L9.69412174,8.64224199 L9.69412174,8.58296942 L13.036648,11.9254957 L12.9773754,11.9254957 Z M14.1568696,10.9419577 L10.6776596,7.46274783 L11.1817043,7.46274783 L11.1817043,4.48758261 C11.1817043,4.07849739 11.5164104,3.7437913 11.9254957,3.7437913 C12.3345809,3.7437913 12.669287,4.07849739 12.669287,4.48758261 L12.669287,7.46274783 L14.1568696,7.46274783 L14.1568696,10.9419577 Z" />
  </SvgIcon>
);

export default SensorError;
