import * as React from "react";
import Badge from "@material-ui/core/Badge";
import MenuIcon from "@material-ui/icons/Menu";
import IconButton from "@material-ui/core/IconButton";

import useStyles from "./DrawerToggle.styles";
import useAlarmsTotal from "../../hooks/useAlarmsTotal";

interface Props {
  onToggle?: () => void;
}

const DrawerToggle: React.FunctionComponent<Props> = ({ onToggle }) => {
  const classes = useStyles();
  const { count } = useAlarmsTotal();
  const Button = (
    <IconButton color="inherit" onClick={onToggle ? onToggle : undefined}>
      <MenuIcon />
    </IconButton>
  );
  if (count > 0) {
    return (
      <Badge color="error" variant="dot" classes={{ badge: classes.badge }}>
        {Button}
      </Badge>
    );
  }
  return Button;
};

export default DrawerToggle;
