import clsx from "clsx";
import * as React from "react";
import Badge from "@material-ui/core/Badge";

import useStyles from "./MenuAlarmBadge.styles";
import { View } from "../../context/treeProvider";

interface AlarmBadgeProps {
  item: View;
  children: React.ReactElement;
  dataTestId?: string;
}

const MenuAlarmBadge: React.FunctionComponent<AlarmBadgeProps> = ({
  item,
  children,
  dataTestId,
}) => {
  const classes = useStyles();

  if (item.alarmCount > 0) {
    return (
      <Badge
        badgeContent={item.alarmCount}
        classes={{
          root: classes.root,
          badge: clsx(classes.badge, {
            [classes.error]: item.alarmCount > 0,
          }),
        }}
        data-testid={dataTestId}
      >
        <div className={classes.wrapper}>{children}</div>
      </Badge>
    );
  }
  return children;
};

export default MenuAlarmBadge;
