import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(
  () => ({
    snackbarFont: {
      fontSize: "1.4rem"
    }
  }),
  { name: "AppIndex" }
);
