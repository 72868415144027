import * as React from "react";
import { useTheme } from "@material-ui/core/styles";
import WarningIcon from "@material-ui/icons/Error";
import OkIcon from "@material-ui/icons/CheckCircle";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import ErrorIcon from "@material-ui/icons/NotificationsActive";
import { Theme } from "@material-ui/core/styles/createMuiTheme";

import SystemIcon from "../Icons/SystemAlarm";
import { Alarm } from "../../generated/graphql";

interface Props {
  alarm?: Alarm;
  inverse?: boolean;
  className?: string;
  IconProps?: SvgIconProps;
  dataTestId?: string;
}

const getAlarmColorStyles = (severity: number | null | undefined, theme: Theme) => {
  switch (severity) {
    case 2:
      return {
        color: theme.palette.error.main,
      };
    case 1:
      return {
        color: theme.palette.warning.main,
      };
    case 0:
      return {
        color: theme.palette.success.main,
      };

    default:
      return {
        color: theme.palette.grey[600],
      };
  }
};

const getAlarmColorStylesInverse = (severity: number | null | undefined, theme: Theme) => {
  switch (severity) {
    case 2:
      return {
        color: theme.palette.error.contrastText,
      };
    case 1:
      return {
        color: theme.palette.warning.contrastText,
      };
    case 0:
      return {
        color: theme.palette.success.contrastText,
      };
    default:
      return {
        color: theme.palette.getContrastText(theme.palette.grey[600]),
      };
  }
};

const AlarmIcon: React.FunctionComponent<Props> = ({
  alarm = { severity: 0, type: 0 },
  inverse = false,
  className,
  IconProps,
  dataTestId,
}) => {
  const theme = useTheme<Theme>();
  const style = inverse
    ? getAlarmColorStylesInverse(alarm.severity, theme)
    : getAlarmColorStyles(alarm.severity, theme);

  let Icon: React.FunctionComponent<SvgIconProps> = OkIcon;
  if (alarm.type === 3) Icon = ErrorIcon;
  if (alarm.type === 2) Icon = WarningIcon;
  if (alarm.type === 1) Icon = SystemIcon;

  return <Icon style={style} {...IconProps} className={className} data-testid={dataTestId} />;
};

export default AlarmIcon;
