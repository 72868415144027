import * as R from "ramda";
import * as React from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import useStyles from "./Log.styles";
import { useLogSubscription } from "../../generated/graphql";

const Log: React.FunctionComponent = () => {
  const classes = useStyles();
  const [logs, setLogs] = React.useState<string[]>([]);
  const [autoScroll, setAutoScroll] = React.useState<boolean>(true);
  const ref = React.createRef<HTMLDivElement>();

  useLogSubscription({
    onSubscriptionData: ({ subscriptionData }) => {
      if (
        subscriptionData &&
        subscriptionData.data &&
        subscriptionData.data.log &&
        subscriptionData.data.log.message
      ) {
        if (logs.length > 100) {
          setLogs(R.append(subscriptionData.data.log.message, R.drop(1, logs)));
        } else {
          setLogs(R.append(subscriptionData.data.log.message, logs));
        }
      }
    },
  });

  React.useEffect(() => {
    if (ref && ref.current && ref.current.scrollTop && ref.current.scrollHeight && autoScroll) {
      ref.current.scrollTop = ref.current.scrollHeight;
    }
  }, [ref, logs, autoScroll]);

  return (
    <>
      <Grid container className={classes.logs} ref={ref}>
        {logs.length > 0 ? (
          logs.map((log, index) => (
            <Typography
              display="block"
              variant="caption"
              key={`log-${index}`}
              className={classes.logMessage}
            >
              {log}
            </Typography>
          ))
        ) : (
          <Typography display="block" variant="caption" className={classes.logMessage}>
            Waiting for log messages ...
          </Typography>
        )}
      </Grid>
      <Grid container className={classes.autoScroll} alignItems="flex-end" direction="column">
        <Button variant="text" onClick={() => setAutoScroll(!autoScroll)} size="small">
          {autoScroll ? "Stop scrolling" : "Start scrolling"}
        </Button>
      </Grid>
    </>
  );
};

export default Log;
