import * as React from "react";

import Auth from "../../utils/auth";
import PageLoadingIndicator from "../../components/PageLoadingIndicator";

export interface AuthState {
  initialized: boolean;
  authenticated: boolean;
  isLoading: boolean;
  error: Error | null;
}

export interface IContext extends AuthState {
  login: (username: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  getJwtToken?: () => string | undefined;
}

export const AuthContext: React.Context<IContext> = React.createContext({} as IContext);

const AuthProvider: React.FunctionComponent = ({ children }) => {
  const [state, setState] = React.useState<AuthState>({
    initialized: false,
    error: null,
    isLoading: false,
    authenticated: false,
  });

  React.useEffect(() => {
    Auth.isAuthenticated()
      .then((authenticated) => {
        setState({ initialized: true, authenticated, error: null, isLoading: false });
      })
      .catch(() => {
        setState({ initialized: true, authenticated: false, error: null, isLoading: false });
      });
  }, []);

  if (state.isLoading) {
    return <PageLoadingIndicator />;
  }

  const login = async (username: string, password: string) => {
    try {
      setState({ ...state, isLoading: true, error: null });
      const authenticated = await Auth.login(username, password);
      setState({ ...state, isLoading: false, authenticated, error: null });
    } catch (error) {
      setState({ ...state, isLoading: false, authenticated: false, error });
    }
  };

  const logout = async () => {
    Auth.logout();
    setState({ ...state, isLoading: false, authenticated: false, error: null });
  };

  if (state.initialized && !state.isLoading) {
    return (
      <AuthContext.Provider
        value={{
          ...state,
          login,
          logout,
          getJwtToken: Auth.getJwtToken,
        }}
      >
        {children}
      </AuthContext.Provider>
    );
  }

  return null;
};

export default AuthProvider;
