import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";
import * as React from "react";

const MemoryFull: React.FunctionComponent<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path d="M18,2c1.1,0,2,0.9,2,2v16c0,1.1-0.9,2-2,2H6c-1.1,0-2-0.9-2-2L4,8l6-6H18z M13,17.5v-2h-2v2H13z M13,13.5v-6h-2v6H13z" />
  </SvgIcon>
);

export default MemoryFull;
