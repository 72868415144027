import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(
  (theme) => ({
    root: {},
    contentRoot: {
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1) / 2,
      top: theme.spacing(1) / 2,
      color: theme.palette.grey[500],
    },
    logo: {
      background: theme.palette.primary.main,
      minWidth: 320,
      padding: theme.spacing(1),
    },
    logoImg: {
      maxHeight: 40,
    },
  }),
  { name: "AppInfo" }
);
