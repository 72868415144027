import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(
  theme => ({
    root: {
      minHeight: "calc(100vh - 64px)"
    }
  }),
  { name: "PageLoadingIndicator" }
);
