import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(
  theme => ({
    badge: {
      top: "17px",
      right: "14px"
    }
  }),
  { name: "MainToolbar_DrawerToggle" }
);
