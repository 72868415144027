import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(
  (theme) => ({
    root: {
      padding: theme.spacing(5),
    },
    titleRoot: {
      margin: theme.spacing(2),
      padding: 0,
      textAlign: "center",
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    stepCounter: {
      textAlign: "center",
      marginBottom: theme.spacing(2),
    },
    description: {
      textAlign: "center",
      marginBottom: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    form: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    actions: {
      marginLeft: theme.spacing(4),
      marginRight: theme.spacing(4),
    },
    inputWrapper: {
      flexGrow: 1,
      paddingRight: 0,
    },
    usernameContainer: {
      marginBottom: theme.spacing(2),
    },
    validationError: {
      textAlign: "center",
      color: theme.palette.error.main,
      marginBottom: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    successIcon: {
      fontSize: 120,
      marginTop: theme.spacing(5),
      color: theme.palette.success.main,
    },
    warningIcon: {
      fontSize: 120,
      marginTop: theme.spacing(5),
      color: theme.palette.warning.main,
    },
    errorIcon: {
      fontSize: 120,
      marginTop: theme.spacing(5),
      color: theme.palette.error.main,
    },
  }),
  { name: "Acknowledgement" }
);
