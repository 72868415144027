import * as React from "react";
import { useApolloClient } from "@apollo/client";
import { GetViewDocument } from "../../generated/graphql";

import { AlarmCounter } from "../../utils/alarms";

const AlarmPollUpdater: React.FunctionComponent<{ totalAlarms: number | null }> = ({
  totalAlarms,
}) => {
  const client = useApolloClient();

  if (AlarmCounter.count !== null && totalAlarms !== null && totalAlarms !== AlarmCounter.count) {
    client.query({ query: GetViewDocument, fetchPolicy: "network-only" });
  }

  AlarmCounter.count = totalAlarms;

  return null;
};

export default AlarmPollUpdater;
