import PowerSupply from "@material-ui/icons/PowerOff";
import LowBattery from "@material-ui/icons/Battery20";
import ScheduleState from "@material-ui/icons/AccessTime";
import BatteryDeffect from "@material-ui/icons/BatteryAlert";
import UpperIcon from "@material-ui/icons/VerticalAlignTop";
import LowerIcon from "@material-ui/icons/VerticalAlignBottom";
import CalibrationDate from "@material-ui/icons/InsertInvitation";

import MobileOff from "../../Icons/MobileOff";
import MemoryLow from "../../Icons/MemoryLow";
import MemoryFull from "../../Icons/MemoryFull";
import SensorError from "../../Icons/SensorError";
import GsmQueueFull from "../../Icons/GsmQueueFull";
import GsmModuleFailed from "../../Icons/GsmModuleFailed";
import GsmNotAvailable from "../../Icons/GsmNotAvailable";
import ConnectionStatus from "../../Icons/ConnectionStatus";
import { AlarmEventsEnum, AlarmTagEnum } from "../../../context/alarmProvider";

export const getEventIcon = (eventType: AlarmEventsEnum) => {
  switch (eventType) {
    case AlarmEventsEnum.LOW_BATTERY:
      return LowBattery;
    case AlarmEventsEnum.CONNECTION_STATUS:
      return ConnectionStatus;
    case AlarmEventsEnum.PWRSPLY:
      return PowerSupply;
    case AlarmEventsEnum.CALIBRATION_DATE:
      return CalibrationDate;
    case AlarmEventsEnum.SENSOR_ERROR:
      return SensorError;
    case AlarmEventsEnum.GSM_NOT_AVAILABLE:
      return GsmNotAvailable;
    case AlarmEventsEnum.GSM_QUEUE_FULL:
      return GsmQueueFull;
    case AlarmEventsEnum.LOW_MEMORY:
      return MemoryLow;
    case AlarmEventsEnum.BATTERY_DEFECT:
      return BatteryDeffect;
    case AlarmEventsEnum.COMMUNICATION_APP:
      return MobileOff;
    case AlarmEventsEnum.MEMORY_FULL:
      return MemoryFull;
    case AlarmEventsEnum.GSM_MODUL_FAILED:
      return GsmModuleFailed;
    case AlarmEventsEnum.ALARM_SCHEDULE_STATE:
      return ScheduleState;
    default:
      return null;
  }
};

export const getTypeIcon = (
  alarmTag: AlarmTagEnum | null | undefined,
  eventType?: AlarmEventsEnum
) => {
  switch (alarmTag) {
    case AlarmTagEnum.LOWER_LIMIT:
    case AlarmTagEnum.LOWER_LIMIT_GRAD:
    case AlarmTagEnum.LOWER_LIMIT_WARNING:
      return LowerIcon;
    case AlarmTagEnum.UPPER_LIMIT:
    case AlarmTagEnum.UPPER_LIMIT_GRAD:
    case AlarmTagEnum.UPPER_LIMIT_WARNING:
      return UpperIcon;
    default:
      if (eventType) return getEventIcon(eventType);
      else return null;
  }
};
