import { useContext } from "react";

import { TreeContext, IContext } from "../context/treeProvider/TreeProvider";

interface UseTree {
  (): IContext;
}

const useTree: UseTree = (): IContext => {
  const treeContext: IContext = useContext(TreeContext);
  return treeContext;
};

export default useTree;
