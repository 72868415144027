import * as React from "react";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import { useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Theme } from "@material-ui/core/styles/createMuiTheme";

import Log from "./Log";
import Logo from "../Logo";
import useStyles from "./index.styles";
import useUser from "../../hooks/useUser";

interface Props {
  open: boolean;
  onClose: () => void;
  dataTestId?: string;
}

const AppInfo: React.FunctionComponent<Props> = ({ open, onClose, dataTestId }) => {
  const user = useUser();
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme<Theme>();
  const matches = useMediaQuery(theme.breakpoints.down("sm"), { noSsr: true });

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      onClose={onClose}
      fullScreen={matches}
      classes={{
        paper: classes.root,
      }}
      data-testid={dataTestId}
    >
      <DialogTitle className={classes.logo} disableTypography>
        <Logo imageClassName={classes.logoImg} forceTesto />
        <IconButton onClick={onClose} className={classes.closeButton}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.contentRoot}>
        <Grid container direction="column">
          <Grid item>
            <Typography variant="h2" gutterBottom>
              {t("about.name")}
            </Typography>
            <Typography variant="body2">
              {t("about.version", { version: process.env.REACT_APP_VERSION })}
            </Typography>
            <Typography variant="body2">
              {t("about.build", { build: process.env.REACT_APP_BUILD })}
            </Typography>
          </Grid>
        </Grid>
        {!!user && !!user.can && user.can("admin") && <Log />}
      </DialogContent>
    </Dialog>
  );
};

export default AppInfo;
