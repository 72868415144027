import "typeface-roboto";
import numbro from "numbro";
import moment from "moment-timezone";

import "moment/locale/cs";
import "moment/locale/da";
import "moment/locale/de";
import "moment/locale/en-gb";
import "moment/locale/es";
import "moment/locale/fr";
import "moment/locale/hu";
import "moment/locale/it";
import "moment/locale/ja";
import "moment/locale/nl";
import "moment/locale/pl";
import "moment/locale/pt";
import "moment/locale/ru";
import "moment/locale/sv";
import "moment/locale/tr";
import "moment/locale/zh-cn";
import "moment/locale/zh-hk";

import cs from "numbro/languages/cs-CZ";
import da from "numbro/languages/da-DK";
import de from "numbro/languages/de-DE";
import en from "numbro/languages/en-GB";
import es from "numbro/languages/es-ES";
import fr from "numbro/languages/fr-FR";
import hu from "numbro/languages/hu-HU";
import it from "numbro/languages/it-IT";
import ja from "numbro/languages/ja-JP";
import nl from "numbro/languages/nl-NL";
import pl from "numbro/languages/pl-PL";
import pt from "numbro/languages/pt-PT";
import ru from "numbro/languages/ru-RU";
import sk from "numbro/languages/sk-SK";
import tr from "numbro/languages/tr-TR";
import zh from "numbro/languages/zh-CN";

import { i18n, i18nInit } from "./localization";

const bootstrap = async () => {
  await i18nInit;
  moment.tz.setDefault(moment.tz.guess());
  console.log(i18n.language);
  moment.locale(i18n.language);
  numbro.registerLanguage(cs);
  numbro.registerLanguage(da);
  numbro.registerLanguage(de);
  numbro.registerLanguage(en);
  numbro.registerLanguage(es);
  numbro.registerLanguage(fr);
  numbro.registerLanguage(hu);
  numbro.registerLanguage(it);
  numbro.registerLanguage(ja);
  numbro.registerLanguage(nl);
  numbro.registerLanguage(pl);
  numbro.registerLanguage(pt);
  numbro.registerLanguage(ru);
  numbro.registerLanguage(sk);
  numbro.registerLanguage(tr);
  numbro.registerLanguage(zh);
  numbro.setLanguage(i18n.language, "en-GB");
  return i18n;
};
export default bootstrap;
