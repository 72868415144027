import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(
  theme => ({
    toolbar: theme.mixins.toolbar,
    root: {
      width: theme.appDrawer.width,
      flexShrink: 0
    },
    paper: {
      width: theme.appDrawer.width
    },
    home: {
      marginTop: theme.spacing(4)
    },
    homeText: {
      paddingLeft: 0
    },
    logo: {
      width: theme.appDrawer.width,
      position: "fixed",
      paddingLeft: theme.spacing(1),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      bottom: 0,
      zIndex: 2
    },
    logoImg: {
      maxHeight: 40
    }
  }),
  { name: "MainMenu" }
);
