import * as React from "react";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";

import useStyles from "./PageLoadingIndicator.styles";

const PageLoadingIndicator: React.FunctionComponent<{
  classes?: any;
}> = props => {
  const classes = useStyles(props);
  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justify="center"
      className={classes.root}
    >
      <CircularProgress />
    </Grid>
  );
};

export default PageLoadingIndicator;
