import numbro from "numbro";
import roundTo from "round-to";
import { TFunction } from "i18next";

/* istanbul ignore next */
export enum TestoUnitsEnum {
  GRAD_C = 1,
  GRAD_F = 2,
  REL_FEUCHTE = 3,
  REL_HUMIDITY = 4,
  GESCHW = 5,
  GESCHWE = 6,
  TAUPUNKT = 7,
  TAUPUNKTE = 8,
  TEMP_DIFF = 9,
  SPANNUNG_V = 11,
  SPANNUNG_MV = 12,
  SPANNUNG_MICV = 13,
  ANZAHL = 15,
  STROM_A = 16,
  STROM_MA = 17,
  STROM_MICA = 18,
  WIDERSTAND_O = 19,
  WIDERSTAND_MO = 20,
  WIDERSTAND_MICO = 21,
  LAMBDA = 22,
  DRUCK_MBAR = 23,
  DRUCK_HPA = 24,
  DRUCK_PSI = 25,
  DRUCK_INHG = 93,
  DRUCK_KPA = 94,
  DIFFDRUCK_HPA = 76,
  DRUCK_INW = 79,
  DRUCK_TORR = 78,
  DRUCK_MMW = 82,
  INCH = 26,
  QA_POLYNOM = 27,
  QA_SIEGERT = 28,
  ABGASTEMP = 29,
  VERBR_LTEMP = 30,
  ABGASTEMPE = 31,
  VERBR_LTEMPE = 32,
  VOLSTROM = 33,
  VOLSTROME = 34,
  VOLSTRMF3PERSEC = 106,
  VOLSTRMF3PERHOUR = 107,
  VOLSTRMF3PERDAY = 108,
  VOLSTRMF3PERYEAR = 109,
  VOLSTROMJ = 77,
  VOLSTRM3PERSEC = 91,
  VOLSTRDM3PERSEC = 92,
  VOLSTROM_NORM = 96,
  VOLSTROME_NORM = 97,
  VOLSTROMJ_NORM = 98,
  VOLSTRM3PERSEC_NORM = 100,
  VOLSTRDM3PERSEC_NORM = 101,
  VOLPROZ_O2 = 35,
  VOLPROZ_O2_BEZ = 138,
  VOLPROZ_CO = 36,
  VOLPROZ_CO2 = 37,
  VOLPROZ_NO = 38,
  VOLPROZ_NO2 = 39,
  VOLPROZ_SO = 40,
  VOLPROZ_SO2 = 41,
  PPM_O2 = 42,
  PPM_CO = 43,
  PPM_CO2 = 44,
  PPM_NO = 45,
  PPM_NO2 = 46,
  PPM_SO = 47,
  PPM_SO2 = 48,
  MG_O2 = 49,
  MG_CO = 50,
  MG_CO2 = 51,
  MG_NO = 52,
  MG_NO2 = 53,
  MG_SO = 54,
  MG_SO2 = 55,
  PH = 56,
  PH_DT = 58,
  LEITWERT_MS = 59,
  LEITWERT_MICS = 60,
  MGPERLITER_O2 = 61,
  SAETTIGUNG = 62,
  MGPERLITER = 63,
  LAENGE_M = 64,
  LAENGE_MM = 65,
  LAENGE_MIC = 66,
  LAENGE_CM = 80,
  LAENGE_FT = 81,
  RUSSZAHL = 67,
  ABGAS_VERL = 68,
  FREQ_KHZ = 128,
  DREHZAHL = 95,
  ABS_HUMIDITY = 129,
  DRY_HUMIDITY = 134,
  VOLPROZ = 130,
  PPM = 131,
  BAR = 133,
  GPERGJ = 135,
  MGPERKWH = 136,
  MGPERM3 = 139,
  DRUCK_PA = 137,
  MGPERM3_O2 = 140,
  MGPERM3_CO = 141,
  MGPERM3_CO2 = 142,
  MGPERM3_NO = 143,
  MGPERM3_NO2 = 144,
  MGPERM3_SO = 145,
  MGPERM3_SO2 = 146,
  GPERGJ_O2 = 147,
  GPERGJ_CO = 148,
  GPERGJ_CO2 = 149,
  LBSPERMBTU = 166,
  RPPM = 167,
  RMGPERM3 = 168,
  RLBSPERMBTU = 169,
  GPERGJ_NO = 170,
  GPERGJ_NO2 = 171,
  GPERGJ_SO = 172,
  GPERGJ_SO2 = 173,
  MGPERKWH_O2 = 174,
  MGPERKWH_CO = 175,
  MGPERKWH_CO2 = 176,
  MGPERKWH_NO = 177,
  MGPERKWH_NO2 = 178,
  MGPERKWH_SO = 179,
  MGPERKWH_SO2 = 180,
  MGPERKWH_NOX = 181,
  GPERGJ_NOX = 182,
  MGPERM3_NOX = 183,
  PPM_NOX = 184,
  BRENNSTOFF = 185,
  VOLSTRM3PERMIN = 186,
  VOLSTRM3PERDAY = 188,
  VOLSTRM3PERANNO = 189,
  TONSPERHOUR = 190,
  KGSPERHOUR = 191,
  KGPERDAY = 192,
  WIDERSTAND_KOHM = 194,
  OELDERIVAT = 195,
  KGM3 = 196,
  GM3 = 197,
  MASSFLOW = 72,
  LITERPERMIN = 73,
  TONSPERDAY = 74,
  TONSPERANNO = 75,
  TIMEDIV = 84,
  WEIGHT = 85,
  VOLUME = 86,
  AREA = 87,
  LONGTONSPERANNO = 88,
  LBSPERH = 89,
  KJPERKG = 90,
  LUX = 102,
  FCANDELA = 103,
  AW = 104,
  ABSDRUCK_HPA = 105,
  GRAMPERHORSEPOWERHOUR = 110,
  FLAECHE_CM2 = 10,
  FLAECHE_MM2 = 57,
  FLAECHE_FT2 = 111,
  FLAECHE_INCH2 = 112,
  GCM3 = 113,
  GRFEET3 = 114,
  POUNDFT3 = 115,
  HORSEPOWER = 116,
  LEISTUNG_KW = 117,
  VOLUME_DM3 = 118,
  WPERM2K = 119,
  FREQ_HZ = 120,
  VOLSTRDM3PERMIN_NORM = 121,
  VOLSTRFT3PERHOUR_NORM = 122,
  MASSE_G = 124,
  MASSE_LB = 125,
  MASSE_OZ = 126,
  LEISTUNG_W = 127,
  DRUCK_MEGAPA = 150,
  DRUCK_MICRON = 151,
  TAUPUNKTTEMP_DIFF = 152,
  BTUPERHOUR = 153,
  TONS = 154,
  KELVIN = 155,
  DRUCKTAUPUNKT = 156,
  DRUCKTAUPUNKTE = 157,
  BTUPERLB = 158,
  GRPERLB = 159,
  GRAD_C_TW = 160,
  GRAD_F_TW = 161,
  GRAD_C_TM = 162,
  GRAD_F_TM = 163,
  TAUPUNKTTEMP_DIFFE = 164,
  REL_HUMIDITY_WMO = 165,
  TEMP_DIFFE = 187,
  MKT_C = 193,
  AMPERE_HOUR = 200,
  VOLT_HOUR = 201,
  DRUCK_KGCM2 = 202,
  C_TDA = 203,
  F_TDA = 204,
  LBPERMSCF = 205,
  GALPERHOUR = 206,
  GALONE = 207,
  VOLSTRDM3PERHOUR = 208,
  MKT_F = 210,
  VOLUME_FT3 = 209,
  US_FLOZPERHOUR = 211,
  MASSE_MG = 212,
  MASSE_MICG = 213,
  MASSE_NG = 214,
  AMGULAR_DEGREE = 215,
  PPM_HOUR = 216,
  MILLILITERPERMIN = 217,
  VOLUME_CM3 = 218,
  RFH2O2 = 219,
  LETH_MIN = 300,
  SCFPERMMBTU = 512,
  BTUPERBHPHR = 513,
  BTUPERGAL = 514,
  BTUPERSCF = 515,
  SCFPERHR = 516,
  LBPERHR = 517,
  MPERSEC2 = 518,
}

/* istanbul ignore next */
export const getUnitString = (unit?: TestoUnitsEnum) => {
  switch (unit) {
    case TestoUnitsEnum.GRAD_C:
      return "°C";
    case TestoUnitsEnum.GRAD_F:
      return "°F";
    case TestoUnitsEnum.REL_FEUCHTE:
      return "%rF";
    case TestoUnitsEnum.REL_HUMIDITY:
      return "% RH";
    case TestoUnitsEnum.GESCHW:
      return "m/s";
    case TestoUnitsEnum.GESCHWE:
      return "ft/min";
    case TestoUnitsEnum.TAUPUNKT:
      return "td °C";
    case TestoUnitsEnum.TAUPUNKTE:
      return "td °F";
    case TestoUnitsEnum.TEMP_DIFF:
      return "T°C";
    case TestoUnitsEnum.SPANNUNG_V:
      return "V";
    case TestoUnitsEnum.SPANNUNG_MV:
      return "mV";
    case TestoUnitsEnum.SPANNUNG_MICV:
      return "µV";
    case TestoUnitsEnum.ANZAHL:
      return "";
    case TestoUnitsEnum.STROM_A:
      return "A";
    case TestoUnitsEnum.STROM_MA:
      return "mA";
    case TestoUnitsEnum.STROM_MICA:
      return "µA";
    case TestoUnitsEnum.WIDERSTAND_O:
      return "kOhm";
    case TestoUnitsEnum.WIDERSTAND_MO:
      return "mOhm";
    case TestoUnitsEnum.WIDERSTAND_MICO:
      return "Ohm";
    case TestoUnitsEnum.LAMBDA:
      return "lambda";
    case TestoUnitsEnum.DRUCK_MBAR:
      return "mbar";
    case TestoUnitsEnum.DRUCK_HPA:
      return "hPa";
    case TestoUnitsEnum.DRUCK_PSI:
      return "psi";
    case TestoUnitsEnum.DRUCK_INHG:
      return "H20";
    case TestoUnitsEnum.DRUCK_KPA:
      return "kPa";
    case TestoUnitsEnum.DIFFDRUCK_HPA:
      return "dhPa";
    case TestoUnitsEnum.DRUCK_INW:
      return "Hg";
    case TestoUnitsEnum.DRUCK_TORR:
      return "Torr";
    case TestoUnitsEnum.DRUCK_MMW:
      return "mmH20";
    case TestoUnitsEnum.INCH:
      return "in";
    case TestoUnitsEnum.QA_POLYNOM:
      return "% qAP";
    case TestoUnitsEnum.QA_SIEGERT:
      return "% qAS";
    case TestoUnitsEnum.ABGASTEMP:
      return "°C AT";
    case TestoUnitsEnum.VERBR_LTEMP:
      return "°C VT";
    case TestoUnitsEnum.ABGASTEMPE:
      return "°F AT";
    case TestoUnitsEnum.VERBR_LTEMPE:
      return "°F VT";
    case TestoUnitsEnum.VOLSTROM:
      return "m³/h";
    case TestoUnitsEnum.VOLSTROME:
      return "f³/min";
    case TestoUnitsEnum.VOLSTRMF3PERSEC:
      return "f³/sec";
    case TestoUnitsEnum.VOLSTRMF3PERHOUR:
      return "f³/hr";
    case TestoUnitsEnum.VOLSTRMF3PERDAY:
      return "f³/d";
    case TestoUnitsEnum.VOLSTRMF3PERYEAR:
      return "f³/a";
    case TestoUnitsEnum.VOLSTROMJ:
      return "m³/min";
    case TestoUnitsEnum.VOLSTRM3PERSEC:
      return "m³/sec";
    case TestoUnitsEnum.VOLSTRDM3PERSEC:
      return "l/s";
    case TestoUnitsEnum.VOLSTROM_NORM:
      return "Nm/h";
    case TestoUnitsEnum.VOLSTROME_NORM:
      return "Nft/min";
    case TestoUnitsEnum.VOLSTROMJ_NORM:
      return "Nm/min";
    case TestoUnitsEnum.VOLSTRM3PERSEC_NORM:
      return "Nm/sec";
    case TestoUnitsEnum.VOLSTRDM3PERSEC_NORM:
      return "Nl/sec";
    case TestoUnitsEnum.VOLPROZ_O2:
      return "% O2";
    case TestoUnitsEnum.VOLPROZ_O2_BEZ:
      return "% O2bez";
    case TestoUnitsEnum.VOLPROZ_CO:
      return "% CO";
    case TestoUnitsEnum.VOLPROZ_CO2:
      return "%CO2";
    case TestoUnitsEnum.VOLPROZ_NO:
      return "% NO";
    case TestoUnitsEnum.VOLPROZ_NO2:
      return "% NO2";
    case TestoUnitsEnum.VOLPROZ_SO:
      return "% SO";
    case TestoUnitsEnum.VOLPROZ_SO2:
      return "% SO2";
    case TestoUnitsEnum.PPM_O2:
      return "ppm O2";
    case TestoUnitsEnum.PPM_CO:
      return "ppm CO";
    case TestoUnitsEnum.PPM_CO2:
      return "ppm CO2";
    case TestoUnitsEnum.PPM_NO:
      return "ppm NO";
    case TestoUnitsEnum.PPM_NO2:
      return "ppm NO2";
    case TestoUnitsEnum.PPM_SO:
      return "ppm SO";
    case TestoUnitsEnum.PPM_SO2:
      return "ppm SO2";
    case TestoUnitsEnum.MG_O2:
      return "mg/m³ O2";
    case TestoUnitsEnum.MG_CO:
      return "mg/m³ CO";
    case TestoUnitsEnum.MG_CO2:
      return "mg/m³ CO2";
    case TestoUnitsEnum.MG_NO:
      return "mg/m³ NO";
    case TestoUnitsEnum.MG_NO2:
      return "mg/m³ NO2";
    case TestoUnitsEnum.MG_SO:
      return "mg/m³ SO";
    case TestoUnitsEnum.MG_SO2:
      return "mg/m³ SO2";
    case TestoUnitsEnum.PH:
      return "pH";
    case TestoUnitsEnum.PH_DT:
      return "dpH/dt";
    case TestoUnitsEnum.LEITWERT_MS:
      return "mS";
    case TestoUnitsEnum.LEITWERT_MICS:
      return "µS";
    case TestoUnitsEnum.MGPERLITER_O2:
      return "mg/l O2";
    case TestoUnitsEnum.SAETTIGUNG:
      return "% SAT";
    case TestoUnitsEnum.MGPERLITER:
      return "mg/l";
    case TestoUnitsEnum.LAENGE_M:
      return "m";
    case TestoUnitsEnum.LAENGE_MM:
      return "mm";
    case TestoUnitsEnum.LAENGE_MIC:
      return "µm";
    case TestoUnitsEnum.LAENGE_CM:
      return "cm";
    case TestoUnitsEnum.LAENGE_FT:
      return "ft";
    case TestoUnitsEnum.RUSSZAHL:
      return "Ruzahl";
    case TestoUnitsEnum.ABGAS_VERL:
      return "% qA";
    case TestoUnitsEnum.FREQ_KHZ:
      return "kHz";
    case TestoUnitsEnum.DREHZAHL:
      return "upm";
    case TestoUnitsEnum.ABS_HUMIDITY:
      return "g/m³";
    case TestoUnitsEnum.DRY_HUMIDITY:
      return "g/kg";
    case TestoUnitsEnum.VOLPROZ:
      return "%";
    case TestoUnitsEnum.PPM:
      return "ppm";
    case TestoUnitsEnum.BAR:
      return "bar";
    case TestoUnitsEnum.GPERGJ:
      return "g/GJ";
    case TestoUnitsEnum.MGPERKWH:
      return "mg/kWh";
    case TestoUnitsEnum.MGPERM3:
      return "mg/m³";
    case TestoUnitsEnum.DRUCK_PA:
      return "Pa";
    case TestoUnitsEnum.MGPERM3_O2:
      return "mg/m³ O2";
    case TestoUnitsEnum.MGPERM3_CO:
      return "mg/m³ CO";
    case TestoUnitsEnum.MGPERM3_CO2:
      return "mg/m³ CO2";
    case TestoUnitsEnum.MGPERM3_NO:
      return "mg/m³ NO";
    case TestoUnitsEnum.MGPERM3_NO2:
      return "mg/m³ NO2";
    case TestoUnitsEnum.MGPERM3_SO:
      return "mg/m³ SO";
    case TestoUnitsEnum.MGPERM3_SO2:
      return "mg/m³ SO2";
    case TestoUnitsEnum.GPERGJ_O2:
      return "g/GJ O2";
    case TestoUnitsEnum.GPERGJ_CO:
      return "g/GJ CO";
    case TestoUnitsEnum.GPERGJ_CO2:
      return "g/GJ CO2";
    case TestoUnitsEnum.LBSPERMBTU:
      return "#BTU";
    case TestoUnitsEnum.RPPM:
      return "ppm";
    case TestoUnitsEnum.RMGPERM3:
      return "mgm3";
    case TestoUnitsEnum.RLBSPERMBTU:
      return "#BTU";
    case TestoUnitsEnum.GPERGJ_NO:
      return "g/GJ NO";
    case TestoUnitsEnum.GPERGJ_NO2:
      return "g/GJ NO2";
    case TestoUnitsEnum.GPERGJ_SO:
      return "g/GJ SO";
    case TestoUnitsEnum.GPERGJ_SO2:
      return "g/GJ SO2";
    case TestoUnitsEnum.MGPERKWH_O2:
      return "mg/kWh O2";
    case TestoUnitsEnum.MGPERKWH_CO:
      return "mg/kWh CO";
    case TestoUnitsEnum.MGPERKWH_CO2:
      return "mg/kWh CO2";
    case TestoUnitsEnum.MGPERKWH_NO:
      return "mg/kWh NO";
    case TestoUnitsEnum.MGPERKWH_NO2:
      return "mg/kWh NO2";
    case TestoUnitsEnum.MGPERKWH_SO:
      return "mg/kWh SO";
    case TestoUnitsEnum.MGPERKWH_SO2:
      return "mg/kWh SO2";
    case TestoUnitsEnum.MGPERKWH_NOX:
      return "mg/kWh NOx";
    case TestoUnitsEnum.GPERGJ_NOX:
      return "g/GJ NOx";
    case TestoUnitsEnum.MGPERM3_NOX:
      return "mg/m³ NOx";
    case TestoUnitsEnum.PPM_NOX:
      return "ppm NOx";
    case TestoUnitsEnum.BRENNSTOFF:
      return "Brennstoff";
    case TestoUnitsEnum.VOLSTRM3PERMIN:
      return "m³/min";
    case TestoUnitsEnum.VOLSTRM3PERDAY:
      return "m³/d";
    case TestoUnitsEnum.VOLSTRM3PERANNO:
      return "m³/a";
    case TestoUnitsEnum.TONSPERHOUR:
      return "t/h";
    case TestoUnitsEnum.KGSPERHOUR:
      return "kg/h";
    case TestoUnitsEnum.KGPERDAY:
      return "kg/d";
    case TestoUnitsEnum.WIDERSTAND_KOHM:
      return "kOhm";
    case TestoUnitsEnum.OELDERIVAT:
      return "lderivat";
    case TestoUnitsEnum.KGM3:
      return "kg/m³";
    case TestoUnitsEnum.GM3:
      return "g/m³";
    case TestoUnitsEnum.MASSFLOW:
      return "kg/h";
    case TestoUnitsEnum.LITERPERMIN:
      return "l/min";
    case TestoUnitsEnum.TONSPERDAY:
      return "t/d";
    case TestoUnitsEnum.TONSPERANNO:
      return "t/a";
    case TestoUnitsEnum.TIMEDIV:
      return "h";
    case TestoUnitsEnum.WEIGHT:
      return "kg";
    case TestoUnitsEnum.VOLUME:
      return "m";
    case TestoUnitsEnum.AREA:
      return "m";
    case TestoUnitsEnum.LONGTONSPERANNO:
      return "t(US)/yr";
    case TestoUnitsEnum.LBSPERH:
      return "lbs/hr";
    case TestoUnitsEnum.KJPERKG:
      return "KJ/kg";
    case TestoUnitsEnum.LUX:
      return "Lux";
    case TestoUnitsEnum.FCANDELA:
      return "fc";
    case TestoUnitsEnum.AW:
      return "aw";
    case TestoUnitsEnum.ABSDRUCK_HPA:
      return "hPaB";
    case TestoUnitsEnum.GRAMPERHORSEPOWERHOUR:
      return "g/hph";
    case TestoUnitsEnum.FLAECHE_CM2:
      return "cm";
    case TestoUnitsEnum.FLAECHE_MM2:
      return "mm";
    case TestoUnitsEnum.FLAECHE_FT2:
      return "ft";
    case TestoUnitsEnum.FLAECHE_INCH2:
      return "in";
    case TestoUnitsEnum.GCM3:
      return "g/cm";
    case TestoUnitsEnum.GRFEET3:
      return "g/ft";
    case TestoUnitsEnum.POUNDFT3:
      return "lbs/ft";
    case TestoUnitsEnum.HORSEPOWER:
      return "PS";
    case TestoUnitsEnum.LEISTUNG_KW:
      return "kW";
    case TestoUnitsEnum.VOLUME_DM3:
      return "l";
    case TestoUnitsEnum.WPERM2K:
      return "W/mK";
    case TestoUnitsEnum.FREQ_HZ:
      return "1/s";
    case TestoUnitsEnum.VOLSTRDM3PERMIN_NORM:
      return "Nl/min";
    case TestoUnitsEnum.VOLSTRFT3PERHOUR_NORM:
      return "Nft/h";
    case TestoUnitsEnum.MASSE_G:
      return "g";
    case TestoUnitsEnum.MASSE_LB:
      return "lbs";
    case TestoUnitsEnum.MASSE_OZ:
      return "oz";
    case TestoUnitsEnum.LEISTUNG_W:
      return "W";
    case TestoUnitsEnum.DRUCK_MEGAPA:
      return "MPA";
    case TestoUnitsEnum.DRUCK_MICRON:
      return "micron";
    case TestoUnitsEnum.TAUPUNKTTEMP_DIFF:
      return "°Cdtp";
    case TestoUnitsEnum.BTUPERHOUR:
      return "BTU/h";
    case TestoUnitsEnum.TONS:
      return "TONS";
    case TestoUnitsEnum.KELVIN:
      return "K";
    case TestoUnitsEnum.DRUCKTAUPUNKT:
      return "°Ctpd";
    case TestoUnitsEnum.DRUCKTAUPUNKTE:
      return "°Ftpd";
    case TestoUnitsEnum.BTUPERLB:
      return "BTU/lb";
    case TestoUnitsEnum.GRPERLB:
      return "gr/lb";
    case TestoUnitsEnum.GRAD_C_TW:
      return "wb°C";
    case TestoUnitsEnum.GRAD_F_TW:
      return "wb°F";
    case TestoUnitsEnum.GRAD_C_TM:
      return "°Ctm";
    case TestoUnitsEnum.GRAD_F_TM:
      return "°Ftm";
    case TestoUnitsEnum.TAUPUNKTTEMP_DIFFE:
      return "°Fdtp";
    case TestoUnitsEnum.REL_HUMIDITY_WMO:
      return "% WMO";
    case TestoUnitsEnum.TEMP_DIFFE:
      return "T°F";
    case TestoUnitsEnum.MKT_C:
      return "°C MKT";
    case TestoUnitsEnum.AMPERE_HOUR:
      return "Ah";
    case TestoUnitsEnum.VOLT_HOUR:
      return "Vh";
    case TestoUnitsEnum.DRUCK_KGCM2:
      return "kg/cm";
    case TestoUnitsEnum.C_TDA:
      return "°CtdA";
    case TestoUnitsEnum.F_TDA:
      return "°FtdA";
    case TestoUnitsEnum.LBPERMSCF:
      return "lb/mscf";
    case TestoUnitsEnum.GALPERHOUR:
      return "USgal/h";
    case TestoUnitsEnum.GALONE:
      return "USgal";
    case TestoUnitsEnum.VOLSTRDM3PERHOUR:
      return "l/h";
    case TestoUnitsEnum.MKT_F:
      return "°F MKT";
    case TestoUnitsEnum.VOLUME_FT3:
      return "ft";
    case TestoUnitsEnum.US_FLOZPERHOUR:
      return "US fl oz/h";
    case TestoUnitsEnum.MASSE_MG:
      return "mg";
    case TestoUnitsEnum.MASSE_MICG:
      return "µg";
    case TestoUnitsEnum.MASSE_NG:
      return "ng";
    case TestoUnitsEnum.AMGULAR_DEGREE:
      return "°";
    case TestoUnitsEnum.PPM_HOUR:
      return "ppmh";
    case TestoUnitsEnum.MILLILITERPERMIN:
      return "ml/min";
    case TestoUnitsEnum.VOLUME_CM3:
      return "cm";
    case TestoUnitsEnum.RFH2O2:
      return "%rFm";
    case TestoUnitsEnum.LETH_MIN:
      return "min";
    case TestoUnitsEnum.SCFPERMMBTU:
      return "scf/MMBTU";
    case TestoUnitsEnum.BTUPERBHPHR:
      return "BTU/(bhp-hr)";
    case TestoUnitsEnum.BTUPERGAL:
      return "BTU/gal";
    case TestoUnitsEnum.BTUPERSCF:
      return "BTU/scf";
    case TestoUnitsEnum.SCFPERHR:
      return "scf/hr";
    case TestoUnitsEnum.LBPERHR:
      return "lb/hr";
    case TestoUnitsEnum.MPERSEC2:
      return "m/s";
    default:
      return "";
  }
};

export const getUnitTypeString = (t: TFunction, unit?: TestoUnitsEnum) => {
  switch (unit) {
    case TestoUnitsEnum.GRAD_C:
    case TestoUnitsEnum.GRAD_F:
      return t("units.temperature");
    case TestoUnitsEnum.REL_HUMIDITY:
      return t("units.relativeHumidity");
    case TestoUnitsEnum.TAUPUNKT:
      return t("units.dewPoint");
    case TestoUnitsEnum.ABS_HUMIDITY:
      return t("units.absoluteHumidity");
    case TestoUnitsEnum.ANZAHL:
      return t("units.amount");
    default:
      return getUnitString(unit);
  }
};

export const parseValue = (rawValue: number, round: Boolean = false): number | null => {
  if (rawValue <= -1.0e30) {
    return null;
  }

  if (round) {
    return roundTo(rawValue, 1);
  }

  return rawValue;
};

export const formatParsedValue = (
  rawValue: number | undefined,
  unit?: TestoUnitsEnum | null
): string => {
  let str = "";

  if (rawValue === undefined || rawValue === null || rawValue <= -1.0e30 || isNaN(rawValue)) {
    return "-----";
  }

  let value = numbro(roundTo(rawValue, 1)).format({
    mantissa: 1,
    trimMantissa: true,
    optionalMantissa: true,
  });
  if (value === "NaN") {
    return "-----";
  }

  if (value) {
    str += value;
    if (unit) {
      str += getUnitString(unit);
    }
  }

  return str;
};

export const formatValue = (rawValue: number | undefined, unit?: TestoUnitsEnum | null): string => {
  if (rawValue) {
    return formatParsedValue(rawValue, unit);
  }

  return "";
};
