import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(
  theme => ({
    button: {
      color: theme.palette.common.white,
      textTransform: "none",
      [theme.breakpoints.up("md")]: {
        margin: theme.spacing(1)
      }
    },
    icon: {
      marginRight: theme.spacing(1),
      color: theme.palette.grey[400],
      transition: `
        opacity ${theme.transitions.duration.standard}ms ${theme.transitions.easing.easeInOut},
        color ${theme.transitions.duration.standard}ms ${theme.transitions.easing.easeInOut}`
    },
    popover: {
      padding: theme.spacing(4),
      minWidth: 300
    },
    brightIcon: {
      color: theme.palette.common.white,
      opacity: 0.7
    }
  }),
  { name: "MainToolbar_UserMenu" }
);
