import * as React from "react";
import { RouteConfig } from "react-router-config";

import PageLayout from "./components/PageLayout";

const View = React.lazy(() => import("./pages/View"));
const Evaluation = React.lazy(() => import("./pages/Evaluation"));
const AlarmCenter = React.lazy(() => import("./pages/AlarmCenter"));
const SecureZones = React.lazy(() => import("./pages/SecureZones"));

const routes: RouteConfig[] = [
  {
    component: PageLayout,
    routes: [
      {
        path: "/",
        exact: true,
        component: View,
      },
      {
        path: "/view",
        exact: true,
        component: View,
      },
      {
        path: "/view/*",
        component: View,
      },
      {
        path: "/evaluation/*",
        component: Evaluation,
      },
      {
        path: "/alarms",
        exact: true,
        component: AlarmCenter,
      },
      {
        path: "/alarms/:limit/:offset?",
        component: AlarmCenter,
      },
      {
        path: "/secure-zones",
        component: SecureZones,
      },
    ],
  },
];

export default routes;
