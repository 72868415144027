import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(
  (theme) => ({
    contentWithSidebar: {
      display: "block",
      flexGrow: 1,
      [theme.breakpoints.up("md")]: {
        marginLeft: theme.appDrawer.width,
      },
    },
    contentWithoutSidebar: {
      display: "block",
      flexGrow: 1,
    },
    toolbarSpacer: theme.mixins.toolbar,
  }),
  { name: "PageLayout" }
);
