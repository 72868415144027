import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(
  (theme) => ({
    root: {
      flexGrow: 1,
      flexShrink: "inherit",
    },
    badge: {
      top: "50%",
      right: theme.spacing(-2),
    },
    wrapper: {
      width: "100%",
      // paddingRight: theme.spacing(4),
    },
    error: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText,
    },
  }),
  { name: "MainMenu_MenuAlarmBadge" }
);
