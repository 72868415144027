import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(
  theme => ({
    root: {
      width: "100vw",
      height: "100vh",
      [theme.breakpoints.down("sm")]: {
        width: "auto",
        background: "#FFF"
      }
    },
    paper: {
      padding: theme.spacing(4),
      [theme.breakpoints.down("sm")]: {
        background: "none",
        boxShadow: "none"
      }
    },
    headerText: {
      textAlign: "center",
      marginBottom: theme.spacing(3)
    },
    sub: {
      color: theme.palette.grey[500]
    },
    formGrid: {
      padding: `0 ${theme.spacing(4)}px`
    },
    fieldGrid: {
      marginBottom: theme.spacing(4),
      width: "100%"
    },
    fieldGridItem: {
      flexGrow: 1
    },
    buttonWrapper: {
      margin: theme.spacing(1),
      position: "relative"
    },
    button: {
      width: "100%"
    },
    buttonProgress: {
      color: theme.palette.warning.dark,
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12
    },
    error: {
      color: theme.palette.error.main
    }
  }),
  { name: "Login" }
);
