import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(
  (theme) => ({
    root: {
      color: theme.palette.grey[800],
      paddingRight: theme.spacing(5),
    },
  }),
  { name: "AlarmList_AlarmListItem" }
);
