import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(
  (theme) => ({
    root: {
      width: "100%",
      marginBottom: 60,
      maxWidth: theme.appDrawer.width,
    },
    rootLogo: {
      maxHeight: "80%",
      overflowY: "auto",
    },
  }),
  { name: "MainMenu_MenuList" }
);
