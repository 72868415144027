import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(
  (theme) => ({
    root: {
      paddingBottom: theme.spacing(5),
      paddingTop: theme.spacing(15),
    },

    circle: {
      display: "flex",
      width: theme.spacing(21),
      height: theme.spacing(21),
      borderRadius: "100%",
      background: theme.palette.grey[200],
      alignItems: "center",
      justifyContent: "center",
    },

    icon: {
      color: theme.palette.grey[400],
      opacity: 0.4,
      fontSize: 100,
    },

    text: {
      textAlign: "center",
      maxWidth: theme.spacing(33),
      paddingTop: theme.spacing(4),
      color: theme.palette.grey[400],
      fontWeight: theme.typography.fontWeightBold,
    },

    textSub: {
      textAlign: "center",
      maxWidth: theme.spacing(33),
      paddingTop: theme.spacing(1),
      color: theme.palette.grey[400],
      fontWeight: theme.typography.fontWeightRegular,
    },
  }),
  { name: "Chart_EmptyChart" }
);
