import * as React from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useApolloClient } from "@apollo/client";

import updateCache from "./update-cache";
import { useAlarmRemovedSubscription } from "../../generated/graphql";

const AlarmRemovedSubscription: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const client = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();

  useAlarmRemovedSubscription({
    onSubscriptionData: async ({ subscriptionData: { data } }) => {
      if (data && data.alarmRemoved) {
        const changed = await updateCache(
          client,
          data.alarmRemoved.alarmsTotal,
          data.alarmRemoved.changed,
          "removed"
        );
        if (changed) {
          enqueueSnackbar(t("acknowledgement.resolvedAlarms", { count: 1 }), {
            variant: "success",
          });
        }
      }
    },
  });

  return null;
};

export default AlarmRemovedSubscription;
